import { message } from "antd";
import { getMetadatas } from 'api/index';
import { createContext, useEffect, useState } from 'react';


export const GlobalMetadataContext = createContext({})

const GlobalMetadataContextProvider = ({ children }) => {
    const [metaDataes, setMetaDataes] = useState()
    const layout = {
        xs: 24,
        sm: 24,
        md: 6,
    }
    const [offset, setOffset] = useState(1)
    const [current, setCurrent] = useState(window.location.hash)

    useEffect(() => {
        getMetadatas().then(res => {
            setMetaDataes(res)
        }).catch(e => {
            message.error("网络错误,请刷新重试")
        }).finally(() => {
        })
    }, [])

    useEffect(() => {
        const tempOffset = window.screen.availWidth > 450 ? 1 : 0
        setOffset(tempOffset)
    }, [offset])

    useEffect(() => {
        if (window.location.hash) {
            setCurrent(window.location.hash)
        } else {
            setCurrent('#/')
        }
    }, [current])

    return (
        <GlobalMetadataContext.Provider
            value={{
                metaDataes,
                layout,
                offset,
                current,
                setCurrent,
            }}
        >
            {children}
        </GlobalMetadataContext.Provider>
    )
}

export default GlobalMetadataContextProvider