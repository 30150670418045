import Style from 'styled-components'

const Styles = Style.div`
  .text{
    font-size:28px;
    color:green
  }
  .row {
    margin-left:10px;
  }
  .pickers {
    width:250px;
    height:35px;
  }
  .btn-delete {
    margin-left:12px;
  }
  .iconincrease {
    margin-top:8px;
    margin-left:4px;
  }
  .icon {
    margin-top:10px;
    margin-left:15px;
  }
  .process-content {
    margin-top:40px;
  }
  @media screen and (max-width: 450px) {
    .add-btn {
      width:300px;
      border-radius: 0;
      margin-top:5px;
    }
    .strategiesTime {
      display:none
    }
  }
  @media screen and (min-width: 450px) {
    .add-btn {
      border-radius: 25px;
      margin-left: 10px;
    }
    
  }
  
  .divider {
    margin-top:30px;
  }
  .footer {
    display: flex;
    justify-content:end;
  }
  
  .content {
    min-height: calc(100vh - 230px);
  }
  .submit {
    margin-left:4px;
  }
  .order-form {
    max-height:600px;
    overflow-y:auto
  }
  
`

export default Styles