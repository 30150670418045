import { Col, DatePicker, Form, Row, Select, Spin, TimePicker } from "antd";
import locale from 'antd/es/date-picker/locale/zh_CN';
import { getCorrespondData } from 'api/index';
import moment from 'moment';
import { StrategiesTimeline } from 'page/components/StrategiesTimeLine';
import { GlobalMetadataContext } from 'page/contexts/GlobalMetadataContext';
import { useContext, useEffect, useState } from "react";
import { disabledEndHours, disabledEndMinutes, disabledHours, disabledMinutes } from 'utils/limitTime';
import { gettimeTableWay } from 'utils/util';
import Styles from "./style.js";
const { Option } = Select

const BasicsFrom = (props) => {
    const { businessesData, isReserve, warehouseFlag, timeTableData, form, selectProcessOneDay } = { ...props }
    const { setSelectProcessOneDay, setBusinessesData, selectWorkDays, setWarehouseFlag, setTimeTableData, setLoading: setLoadings } = props
    const { metaDataes: metaDatas, layout, offset } = useContext(GlobalMetadataContext)

    const [beginTime, setBeginTime] = useState(moment().add(0, "day").valueOf())

    const [endTime, setEndTime] = useState()
    const [getBookingdStartTime, setGetBookingdStartTime] = useState()

    const [selectOneDay, setSelectOneDay] = useState()
    const [selectArea, setSelectArea] = useState()
    const [timeLineloading, setTimeLineLoading] = useState(false)

    const [areaData, setAreaData] = useState([])
    const [getSelectParks, setGetSelectParks] = useState()
    const [showStartTime, SetshowStartTime] = useState(true)

    useEffect(() => {
        let limitDate
        if (businessesData.workdays) {
            limitDate = businessesData.workdays.length - 1
        } else {
            limitDate = 4
        }
        setEndTime(moment().add(limitDate, "day").valueOf())
    }, [businessesData])

    useEffect(() => {
        getMetadatas()
    }, [])

    const getMetadatas = async () => {
        try {
            setLoadings(true)
            const correspondData = await getCorrespondData('00000001')
            setBusinessesData(correspondData)
        } catch (error) {

        } finally {
            setLoadings(false)
        }
    }

    // 获取开始时间
    const changeStartTime = (value) => {
        setGetBookingdStartTime(value)
    }

    // 获取日期
    const selectDay = (value) => {
        SetshowStartTime(false)
        if (value) {
            setWarehouseFlag(true)
        } else {
            setWarehouseFlag(false)
        }
        setSelectProcessOneDay(moment(value).format("YYYY-MM-DD"))

    }


    // 根据日期选择流程
    useEffect(() => {
        let tempBookingData = JSON.parse(sessionStorage.getItem('bookingData'))
        const tempDay = moment(selectOneDay).format("yyyy-MM-DD")

        if (selectOneDay) {
            if (!isReserve) {
                SetshowStartTime(true)
            } else {
                SetshowStartTime(false)
            }
        }
        const data = form.getFieldsValue()
        if (tempBookingData) {
            if (tempDay !== moment(data.day).format("YYYY-MM_DD")) {
                data.time = ''
                form.setFieldsValue({ ...data })
            }
        }

    }, [selectOneDay])

    const getTimeTable = async (newSelectOneDay) => {
        if (selectOneDay && selectArea) {
            try {
                setTimeLineLoading(true)
                const getTimeTableData = await gettimeTableWay(selectArea, newSelectOneDay)
                setTimeTableData(getTimeTableData)
            } catch (error) {

            } finally {
                setTimeLineLoading(false)
            }
        }
    }

    useEffect(() => {
        console.log(selectOneDay, selectArea, selectProcessOneDay);
        let newSelectOneDay
        newSelectOneDay = moment(selectOneDay).format("YYYY-MM-DD")
        const data = JSON.parse(sessionStorage.getItem("bookingData"))
        if (data?.business_process?.area_id) {
            setSelectArea(data.business_process.area_id)
            newSelectOneDay = moment(data.day).format("YYYY-MM-DD")
            setSelectOneDay(data.day)
        }
        getTimeTable(newSelectOneDay)

    }, [selectOneDay, selectArea, selectProcessOneDay])

    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem("bookingData"))
        const localModify = JSON.parse(sessionStorage.getItem("modifyData"))
        businessesData.parks && businessesData.parks.some((res) => {
            if (data) {
                if (res.id === data.park_id) {
                    setAreaData(res.areas)
                }
            }
            if (localModify) {
                if (res.id === localModify.park_id) {
                    setAreaData(res.areas)
                }
            }

        })
        if (data?.business_process?.area_id) {
            if (getSelectParks !== data.business_process.area_id && getSelectParks !== undefined) {
                data.day = data.day ? moment(data.day) : ''
                data.time = data.time ? moment(data.time) : ''
                data.business_process.area_id = ''
                form.setFieldsValue({ ...data })
                sessionStorage.setItem("bookingData", JSON.stringify(data))
            }
        }

    }, [getSelectParks, businessesData])

    const changeArea = (value) => {
        setSelectArea(value)
    }

    const changeParks = (value) => {
        setGetSelectParks(value)
    }

    // 限制开始时间结束时间
    const disabledRangeTime = (_, type) => {
        if (type === 'start') {
            return {
                disabledHours: () => disabledHours(),
                disabledMinutes: () => disabledMinutes(),
            };
        }
        return {
            disabledHours: () => disabledEndHours(),
            disabledMinutes: () => disabledEndMinutes(),
        };
    };

    return (
        <Styles>
            <Row>
                <Col {...layout}>
                    <Form.Item
                        label="预约园区"
                        name="park_id"
                        rules={[{ required: true, message: "请输入预约园区" }]}
                        labelCol={{ style: { width: 80 } }}
                    >
                        <Select onChange={changeParks} disabled={!isReserve}>
                            {
                                metaDatas && metaDatas.parks.map((res, index) => {
                                    return (<Option value={res.id} key={index}>{res.name}</Option>)
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...layout} offset={offset}>
                    <Form.Item
                        label="受访区域"
                        name={["business_process", "area_id"]}
                        rules={[{ required: true, message: "请输入受访区域" }]}
                        labelCol={{ style: { width: 80 } }}
                    >
                        <Select onChange={changeArea} disabled={!isReserve}>
                            {
                                areaData && areaData.map(res => {
                                    return <Option value={res.id} key={res.id}>{res.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col {...layout} style={{ top: '30px' }}>
                    <Form.Item
                        label="预约日期"
                        name="day"
                        rules={[{ required: true, message: "请输入预约日期" }]}
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            showToday
                            format="YYYY-MM-DD"
                            disabledDate={(date) =>
                                (endTime && date > moment(endTime).endOf("day")) ||
                                (beginTime && date <= moment(beginTime).startOf("day"))
                            }
                            onChange={selectDay}
                            locale={locale}
                            disabled={!isReserve}
                        />
                    </Form.Item>
                </Col>

                <Col {...layout} style={{ top: '30px' }} offset={offset}>
                    <Form.Item
                        label="服务时间"
                        name="time"
                        rules={[{ required: true, message: "请输入开始时间" }]}
                    >
                        <TimePicker.RangePicker
                            disabledTime={disabledRangeTime}
                            minuteStep={30} format="HH:mm"
                            showNow={false}
                            style={{ width: '100%' }}
                            inputReadOnly={true}
                            hideDisabledOptions={true}
                            disabled={showStartTime}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {
                isReserve || warehouseFlag ? (<Row className="strategiesTime">
                    <Col span={18} >
                        <Spin spinning={timeLineloading}>
                            {!timeLineloading && <StrategiesTimeline timeTableData={timeTableData} selectOneDay={moment(selectOneDay).format("YYYY-MM-DD")} />}
                        </Spin>

                    </Col>
                </Row>) : null
            }
        </Styles>
    )
}
export default BasicsFrom