import { useState, useEffect, useContext } from 'react';
import { Button, Col, Form, Input, message, Row, Select, Tooltip } from "antd";
import { InfoCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import { GlobalMetadataContext } from '../../contexts/GlobalMetadataContext';
import Styles from "./style";


const { Option } = Select;

const IdentificationType = {
    Identification: 'Identification',
    IDType: 'IDType'
}

const PersonInfoMation = ({ item, index, visitorInfos, setVisitorInfos, fillTheNewData, IDCardType, setIDCardType, fillTheModificationData, isReserve, form }) => {

    const [isCorrectIdcard, setIsCorrectIdcard] = useState(true)
    const { metaDataes:metaDatas, layout} = useContext(GlobalMetadataContext)
    const [offset, setOffset] = useState(1)

    const init = async () => {
        const { visitor_infos } = JSON.parse(sessionStorage.getItem("bookingData")) || { visitor_infos: [] }
        const passport_area_id = visitor_infos && visitor_infos[index]?.passport_area_id;
        const passport_type_id = visitor_infos && visitor_infos[index]?.passport_type_id;
        if (passport_area_id && metaDatas) {
            const newData = metaDatas.acceptable_passports.filter((type) => type.id === passport_area_id)
            setIDCardType(newData[0].passport_types)
        }
    }

    useEffect(() => {
        init()
        // 是否需要偏移
        const tempOffset = window.screen.availWidth > 450 ? 1 : 0
        setOffset(tempOffset)
    }, [metaDatas])

    // 增加组件
    const add = () => {
        const newRow = {
            name: "",
            passport_num: "",
            phone_num: "",
            passport_area_id: "",
            plate_num: "",
            passport_type_id: ''
        };
        setVisitorInfos([...visitorInfos, newRow])
    }

    const deleteComponent = (index) => {
        const tempDeleteData = form.getFieldsValue()
        const infos = cloneDeep(visitorInfos);
        infos.splice(index, 1);
        setVisitorInfos(infos);
        const deleteData = JSON.parse(sessionStorage.getItem("bookingData"))
        if (deleteData) {
            if (tempDeleteData.visitor_infos.length > 0) {
                let arr1 = tempDeleteData.visitor_infos.filter((res, sub) => {
                    return sub !== index
                })
                tempDeleteData.visitor_infos = arr1
                sessionStorage.setItem("bookingData", JSON.stringify(tempDeleteData))
            }
            fillTheNewData()
        }
    }

    const selsctPassport = (e, index, name) => {
        switch (name) {
            case IdentificationType.Identification: {
                const formTempValues = form.getFieldsValue()
                formTempValues.visitor_infos && formTempValues.visitor_infos.map((res, subscript) => {
                    if (index === subscript) {
                        if (res.passport_type_id !== e) {
                            res.passport_type_id = ''
                            form.setFieldsValue({ ...formTempValues })
                        }
                    }
                })
                const { acceptable_passports } = metaDatas || { acceptable_passports: [] };
                const newData = acceptable_passports.filter((type) => type.id === e);
                return setIDCardType(newData[0].passport_types);
            }
            default: {
                e === '1' ? setIsCorrectIdcard(false) : setIsCorrectIdcard(true)
            }
        }
    }

    const checkPassportNum = (saveData, newValue) => {
        const tempVisitors = saveData && saveData.visitor_infos
        const setData = tempVisitors.filter((res, subscript) => index !== subscript)
        setData && setData.map((res) => {
            if (res.passport_num == newValue) {
                return message.error("证件号码不可以相同,请重新输入")
            }
        })
    }

    // 校检证件类型是否相同
    const chengaPassportNum = (e, index) => {
        const newValue = e.target.value
        if (isReserve) {
            const saveData = JSON.parse(sessionStorage.getItem("bookingData"))
            checkPassportNum(saveData, newValue)
        } else {
            const saveData = JSON.parse(sessionStorage.getItem("modifyData"))
            checkPassportNum(saveData, newValue)
        }
    }

    return <Styles>
        <Row className="row" >
            <Col {...layout}>
                <Form.Item
                    label="姓名"
                    rules={
                        [
                            { required: true, message: "请输入姓名" },
                            {
                                required: false,
                                pattern: new RegExp(/^([\u4e00-\u9fa5]{2,20}|[a-zA-Z.\s]{2,20})$/, "g"),
                                message: '不能输入除中英文其他的字符'
                            }
                        ]}
                    name={["visitor_infos", index, "name"]}
                    labelCol={{ style: { width: 80 } }}
                >
                    <Input maxLength={50} />
                </Form.Item>
            </Col>
            <Col {...layout} offset={offset}>
                <Form.Item
                    label="联系方式"
                    name={["visitor_infos", index, "phone_num"]}
                    rules={[
                        { required: true, message: "请输入联系方式" },
                        {
                            required: false,
                            pattern: new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, "g"),
                            message: '请输入正确的手机号'
                        }]}
                    labelCol={{ style: { width: 80 } }}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col {...layout} offset={offset}>
                <Form.Item
                    label="车辆号码"
                    name={["visitor_infos", index, "plate_num"]}
                    rules={[
                        {
                            pattern: new RegExp(/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/),
                            message: '请输入正确的车辆号码'
                        }]}
                    labelCol={{ style: { width: 80 } }}
                >
                    <Input
                        maxLength={50}
                        suffix={
                            <Tooltip title="例如: 京A12345D, 京A00599, 京AD92035, 粤ZF123澳,粤ZF123港">
                                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                            </Tooltip>
                        } />
                </Form.Item>
            </Col>
            <Col {...layout}>
                <Form.Item
                    label="证件区域"
                    name={["visitor_infos", index, "passport_area_id"]}
                    rules={[{ required: true, message: "请输入证件区域" }]}
                    labelCol={{ style: { width: 80 } }}
                >
                    <Select onChange={(e) => { selsctPassport(e, index, IdentificationType.Identification) }}>
                        {
                            metaDatas && metaDatas.acceptable_passports.map(res => {
                                return (<Option value={res.id} key={res.id}>{res.name}</Option>)
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>
            <Col {...layout} offset={offset}>
                <Form.Item
                    label="证件类型"
                    rules={[{ required: true, message: "请输入证件类型" }]}
                    name={["visitor_infos", index, "passport_type_id"]}
                    labelCol={{ style: { width: 80 } }}
                >
                    <Select onChange={(e) => { selsctPassport(e, index, IdentificationType.IDType) }}>
                        {
                            IDCardType && IDCardType.map(res => {
                                return (<Option value={res.type.id} key={res.type.id}>{res.type.name}</Option>)
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>
            <Col {...layout} offset={offset}>
                <Form.Item
                    label="证件号码"
                    rules={
                        isCorrectIdcard ?
                            [
                                { required: true, message: "请输入证件号码" },
                            ] :
                            [
                                { required: true, message: "请输入证件号码" },
                                {
                                    required: false, pattern: new RegExp(/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, "g"),
                                    message: '请输入正确的身份证号码'
                                }
                            ]
                    }
                    name={["visitor_infos", index, "passport_num"]}
                    labelCol={{ style: { width: 80 } }}
                >
                    <Input maxLength={18} onChange={(e) => { chengaPassportNum(e, index) }} />
                </Form.Item>
            </Col>
            <Button
                disabled={visitorInfos.length >= 10}
                onClick={add}
                icon={<PlusOutlined />}
                shape="circle"
                className='add-btn'>{offset === 0 ? "新添加成员" : ""}</Button>
            {
                index > 0 ? <Button
                    onClick={() => deleteComponent(index)}
                    icon={<MinusOutlined />} className='add-btn'>{offset === 0 ? "删除新成员" : ""}</Button> : null
            }
        </Row>
    </Styles>;
}

export default PersonInfoMation;