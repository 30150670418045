import { getTimetable } from '../api'
import { message } from "antd"
import moment from 'moment'

export async function gettimeTableWay(selectArea, newSelectOneDay) {
    let timetableData = {}
    try {
        let tempTimeData = await getTimetable({ selectedArea: selectArea, selectedDay: newSelectOneDay })
        let groups = []
        let items = []
        tempTimeData.map((res, index) => {
            res.title = res.nickname
            res.id = index + 1
            groups.push(res)
            res.occupied_time.forEach((v, indexs) => {
                v.group = index + 1
                v.start_time = moment(`${res.day} ${v.start_time}`)
                v.end_time = moment(`${res.day} ${v.end_time}`)
                items.push(v)
            })
        })
        items.forEach((res, index) => {
            res.id = index
        })
        timetableData.groups = groups
        timetableData.items = items

    } catch (error) {
        message.error('发生网络错误，请重试')
    }
    return timetableData
}

export function getNewlineValue(value) {
    let tempArr = value.replace(/[,，\s]+/gmi, ',').split(',').filter(_ => _)
    const regx = /^[0-9a-zA-Z\\-]{1,32}$/
    const flag = tempArr.every((res) => {
        return regx.test(res)
    })
    return { tempArr, flag }
}

export function deWeight(arr) {
    let newarr = [];
    for (var i = 0; i < arr.length; i++) {
        if (newarr.indexOf(arr[i]) === -1) {
            newarr.push(arr[i]);
        }
    }
    return newarr;
}

export function isMobile() {
    if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        return true; // 移动端
    } else {
        return false; // PC端
    }
}

// 身份证号码验证
const cnidCheck = (cnid) => {
    const idStr = cnid.toLowerCase().trim()

    if (idStr.length !== 18) {
        return false
    }

    const first17 = idStr.substr(0, 17)
    const checkDigit = idStr.substr(17, 1)

    const nu = Array.from(first17).map((c) => Number.parseInt(c))

    const tmp = (
        nu[0] * 7
        + nu[1] * 9
        + nu[2] * 10
        + nu[3] * 5
        + nu[4] * 8
        + nu[5] * 4
        + nu[6] * 2
        + nu[7] * 1
        + nu[8] * 6
        + nu[9] * 3
        + nu[10] * 7
        + nu[11] * 9
        + nu[12] * 10
        + nu[13] * 5
        + nu[14] * 8
        + nu[15] * 4
        + nu[16] * 2
    )

    const remainder = Math.round(tmp) % 11
    const validDigit = (10 - remainder + 2) % 11
    const validDigitStr = validDigit !== 10 ? String(validDigit) : 'x'

    return validDigitStr === checkDigit
}

export default cnidCheck


