import { Button, Descriptions, Form, Input, Modal, Select, Space, Spin, message } from "antd";
import { deleteBookings, filterAppointmentData, getCorrespondData } from 'api/index';
import { isEmpty } from 'lodash';
import moment from 'moment';
import Material from 'page/components/material';
import { GlobalMetadataContext } from 'page/contexts/GlobalMetadataContext';
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getNewlineValue } from 'utils/util.js';
import Styles from "./style";

const { Option } = Select

// 查询预约组件
const SearchDetail = (props) => {
    const navigate = useNavigate()

    const { getIndex, setrepariData, submitData, } = props
    let { bookingList: tempBookingData } = { ...props }
    const [cancelVisible, setCancelVisible] = useState(false)
    const [isModify, setIsModify] = useState(false)

    // 时间在24小时之内不可以进行修改
    useEffect(() => {
        const getSearchData = getIndex
        if (!isEmpty(getSearchData)) {
            const nowTime = moment()
            const d = `${getSearchData[0].day} ${getSearchData[0].started_time}`
            const bookingTime = moment(d)
            const flag = nowTime.isBefore(bookingTime);
            if (flag) {
                setIsModify(false)
            } else {
                setIsModify(true)
            }
        }
    }, [isModify])

    // 取消预约弹框
    const cancelModal = (flag) => {
        setCancelVisible(true)
    }

    // 确认取消预约
    const sureCancel = async (data) => {
        let newArr = {}
        newArr.order_nums = data[0].business_process.order_nums
        newArr.supplier_id = data[0].business_process.supplier_num
        try {
            await deleteBookings({ bookingNum: data[0].booking_num, newArr: newArr })
            message.success('取消预约成功')

        } catch (error) {
            if (error.data.detail.res_msg) {
                message.error(error.data.detail.res_msg)
            } else {
                message.error('取消预约失败，请检查后再试')
            }
        } finally {
            setCancelVisible(false)
        }
    }

    // 取消预约弹框
    const cancel = () => {
        setCancelVisible(false)
    }

    const showModal = (value) => {
        const { business_process, visitor_infos, invoices } = value[0]
        let handleInvoices
        if (!isEmpty(invoices)) {
            handleInvoices = invoices.map(item => {
                item.po_items = item.items
                item.code = item.purchase_order_code
                delete item.items
                return item
            });
        }

        const tempArr = {
            visitor_infos: visitor_infos.map(res => ({
                name: res.visitor_info.visitor.name,
                passport_area_id: res.visitor_info.visitor.passport_area.id,
                passport_num: res.visitor_info.visitor.passport_num,
                passport_type_id: res.visitor_info.visitor.passport_type.id,
                phone_num: res.visitor_info.phone_num,
                plate_num: res.visitor_info.plate_num
            })),
            business_process: {
                area_id: business_process.area.id,
                need_fork_truck: business_process.need_fork_truck,
                order_nums: business_process.order_nums,
                process_strategy_id: business_process.strategy.id,
                purchasing_group_id: business_process?.purchasing_group?.id || null,
                supplier_name: business_process.supplier_name,
                supplier_num: business_process.supplier_num,
                notes: business_process.notes,
                department_id: "仓库"
            },
            invoices: handleInvoices,
            day: value[0].day,
            park_id: value[0].park_id,
            process_type_id: value[0].process_type.id,
            time: [
                moment().format("YYYY-MM-DD") + " " + business_process.allocation.allocated_started_time,
                moment().format("YYYY-MM-DD") + " " + business_process.allocation.allocated_ended_time
            ]
        }
        const bookingsId = { id: value[0].id, businessProcessId: business_process.id }
        sessionStorage.setItem("business_processId", JSON.stringify(bookingsId))
        sessionStorage.setItem("modifyData", JSON.stringify(tempArr))
        navigate("/edit/appointment")
    }

    return (
        <Styles>
            {
                tempBookingData && tempBookingData.length !== 0 ? (
                    <div>
                        {tempBookingData[0].visitor_infos.map((res, index) => {
                            return (
                                <div key={index} >
                                    <Descriptions key={index} title="详细信息">
                                        <Descriptions.Item label="来访人姓名">
                                            {res.visitor_info.visitor.name}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="联系方式">
                                            {res.visitor_info.phone_num}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="证件号码">
                                            {res.visitor_info.visitor.passport_num}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="车辆号码">
                                            {res.visitor_info.plate_num ? res.visitor_info.plate_num : '无'}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </div>
                            )
                        })}
                        <Descriptions title="其他">
                            <Descriptions.Item label="预约编号">{tempBookingData[0].booking_num}</Descriptions.Item>
                            <Descriptions.Item label="受访部门">仓库</Descriptions.Item>
                            <Descriptions.Item label="预约园区">{tempBookingData[0].business_process.area.name}</Descriptions.Item>
                            <Descriptions.Item label="供应商名称">{tempBookingData[0].business_process.supplier_name || `无`}</Descriptions.Item>
                            <Descriptions.Item label="采购组别">{tempBookingData[0].business_process.purchasing_group ? tempBookingData[0].business_process.purchasing_group.name : `无`}</Descriptions.Item>
                            <Descriptions.Item label="供应商编号">{tempBookingData[0].business_process.supplier_num || `无`}</Descriptions.Item>
                            <Descriptions.Item label="来访事由">{tempBookingData[0].process_type.name}</Descriptions.Item>
                            <Descriptions.Item label="订单号">{tempBookingData[0].business_process.order_nums ? tempBookingData[0].business_process.order_nums.join('\n') : `无`}</Descriptions.Item>
                            <Descriptions.Item label="是否需要叉车">{tempBookingData[0].business_process.need_fork_truck ? '是' : '否'}</Descriptions.Item>
                            <Descriptions.Item label="预约日期">{tempBookingData[0].day}</Descriptions.Item>
                            <Descriptions.Item label="开始时间">{tempBookingData[0].business_process.allocation.allocated_started_time}</Descriptions.Item>
                            <Descriptions.Item label="结束时间">{tempBookingData[0].business_process.allocation.allocated_ended_time}</Descriptions.Item>
                            <Descriptions.Item label="流程">{tempBookingData[0].business_process.strategy.name}</Descriptions.Item>
                            <Descriptions.Item label="审批状态">{tempBookingData[0].business_process.state.name}</Descriptions.Item>
                        </Descriptions>
                        <Material />
                        <Space align="end">
                            <Button className="mock-block" onClick={() => showModal(getIndex)} disabled={isModify}>
                                修改
                            </Button>
                            <Button className="mock-block-cancel" onClick={() => cancelModal(getIndex)}>
                                取消预约
                            </Button>
                        </Space>
                        <Modal title="确认取消预约" visible={cancelVisible} onOk={() => {
                            sureCancel(getIndex)
                        }} onCancel={cancel}>
                            <p style={{ color: 'red' }}>此过程不可回撤，请确认是否取消预约</p>
                        </Modal>
                    </div>) : (<div style={{ textAlign: 'center' }}>暂无数据</div >)
            }
        </Styles>
    )
}

const SearchAppointment = () => {
    const { setCurrent } = useContext(GlobalMetadataContext)
    const [detailPage, setDetailPage] = useState(true)
    const [repariData, setrepariData] = useState()
    const [bookingList, setBookingList] = useState([])
    const [searchLoading, setSearchLoading] = useState(false)
    const [deliveryFlag, setDeliveryFlag] = useState(false)
    const [dynamicAcquisitionProcess, setDynamicAcquisitionProcess] = useState()
    const [filterData, setFilterData] = useState()


    useEffect(() => {
        getCorrespondData('00000001').then(res => {
            setDynamicAcquisitionProcess(res.process_strategies)
        }).catch(err => {
            message.error("网络错误，请刷新重试")
        })
        setCurrent('#/search')
    }, [])

    // 根据流程选择是否填写供应商编号，订单号
    const changeProcess = (id) => {
        const deliveryIds = ['s0000000000000000001', 's0000000000000000003']
        setDeliveryFlag(deliveryIds.includes(id))
    }

    // 提交预约信息
    const queryReservation = async (value) => {
        submitData(value)
        setFilterData(value)
    }

    const submitData = async (value) => {
        setSearchLoading(true)
        if (value.business_filter) {
            if (value.business_filter.order_nums) {
                const { tempArr } = getNewlineValue(value.business_filter.order_nums)
                value.business_filter.order_nums = tempArr
            } else {
                value.business_filter.order_nums = []
            }
        }
        try {
            const data = await filterAppointmentData(value);
            // if (!isEmpty(data[0].invoices)) {
            //     data[0].invoices.map(res => {
            //         res.code = res.purchase_order_code
            //     })
            // }
            setBookingList(data);
            setDetailPage(false);
            sessionStorage.setItem("appointmentDetailsData", JSON.stringify(data));
        } catch (error) {
            if (error.data.detail) {
                message.error(`${error.data.detail.res_msg}请填写正确的流程`)
                if (!error.data.detail.res_msg) {
                    message.error('请填写正确的流程')
                }
            } else {
                message.error('发生错误，请重试')
            }
        } finally {
            setSearchLoading(false)
        }
    }

    return (
        <Styles>
            {detailPage ? (
                <Spin spinning={searchLoading}>
                    <div className="parent">
                        <div className="child">
                            <Form
                                name="basic"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValues={{ remember: true }}
                                onFinish={queryReservation}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="流程"
                                    name="process_type_id"
                                    rules={[{ required: true, message: "请输入流程" }]}
                                >
                                    <Select onChange={changeProcess}>
                                        {
                                            dynamicAcquisitionProcess && dynamicAcquisitionProcess.map(res => {
                                                return <Option value={res.id} key={res.id}>{res.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                {
                                    deliveryFlag ? (
                                        <>
                                            <Form.Item
                                                label="供应商编号"
                                                name={["business_filter", "supplier_id"]}
                                                rules={[{ required: deliveryFlag, message: "请输入供应商编号" }]}
                                            >
                                                <Input size="large" maxLength={50} />
                                            </Form.Item>
                                            <Form.Item
                                                label="订单号"
                                                name={["business_filter", "order_nums"]}
                                                rules={[{ required: deliveryFlag, message: "请输入订单号" }]}
                                                extra="请以英文逗号分隔,例:123,456或换行输入"
                                            >
                                                <Input.TextArea maxLength={10000} autoSize={{ minRows: 4, maxRows: 6 }} />
                                            </Form.Item>
                                        </>
                                    ) : null
                                }

                                <Form.Item
                                    label="预约编号"
                                    name="booking_num"
                                    rules={[{ required: true, message: "请输入预约编号" }]}
                                >
                                    <Input size="large" maxLength={50} />
                                </Form.Item>


                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        className="submit"
                                    >
                                        提交
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Spin>
            ) : (
                <div>
                    <Button type="primary" style={{ float: "right" }} onClick={() => { return setDetailPage(true) }} size="small">
                        返回
                    </Button>
                    <SearchDetail
                        bookingList={bookingList}
                        getIndex={bookingList}
                        setrepariData={setrepariData}
                    />
                </div>
            )}
        </Styles>
    )
}
export default SearchAppointment
