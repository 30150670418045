import { Button, Table } from 'antd';
import logo from 'assets/logo.png';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import JSZip from 'jszip';
import { isEmpty } from 'lodash';
import QRCode from 'qrcode.react';
import { useState } from 'react';
import { columns } from './data';
import MaterialStyle from "./style";


const MaterialDetail = ({ res: MaterialDetailData, indexindex, day, booking_num }) => {
    return (
        <>
            <MaterialStyle>
                <div id={`card_${indexindex}`} className='material-body' >
                    <div className='material-content'>
                        <div className='header'>
                            <div style={{ height: "auto", margin: "0 auto", maxWidth: 85, width: "100%", marginTop: '32px', marginLeft: '30px' }} >
                                <QRCode
                                    id="qrCode"
                                    value={MaterialDetailData.code}
                                    size={65}
                                    fgColor="#000000"
                                />
                                <div style={{ fontSize: '10px' }}>送货单号</div>
                            </div>
                            <div className='header-left'>
                                <img src={logo} alt="伯恩logo" />
                                <sapn className="title">送货单</sapn>
                            </div>

                            <div style={{ height: "auto", margin: "0 auto", maxWidth: 85, width: "100%", marginTop: '32px' }} >

                                <QRCode
                                    id="qrCode"
                                    value={JSON.stringify({ "booking_num": booking_num })}
                                    size={65}
                                    fgColor="#000000"
                                />
                                <div style={{ fontSize: '10px' }}>预约编号</div>
                            </div>
                        </div>
                        <div className='main-header'>
                            <div className='top'>
                                <p>收货单位：伯恩公司</p>
                            </div>
                            <div className='bottom'>
                                <p >收货仓库：{MaterialDetailData.default_warehouse_name}</p>
                                <p>采购订单：{MaterialDetailData.purchase_order_code}</p>
                                <p>送货单：{MaterialDetailData.code}</p>
                                <p>日期：{day}</p>
                            </div>
                        </div>
                        <div className='table-content'>
                            <Table dataSource={MaterialDetailData.items} size='small' columns={columns} pagination={false} bordered={true} className='table-right' />
                            <div className="table-notice">付款联(白) 仓库联(粉)供应商联(黄)</div>
                        </div>
                        <div className='footer'>
                            <p>收货员:</p>
                            <p>入库员:</p>
                            <p>收货盖章:</p>
                            <p>送货单位盖章:</p>
                        </div>
                    </div>
                </div>
            </MaterialStyle>
        </>
    );
};

const Material = () => {
    const materialSessionStorageData = JSON.parse(sessionStorage.getItem("appointmentDetailsData"))
    const { invoices, day, booking_num } = materialSessionStorageData[0]
    const [btnLoadings, setbtnLoadings] = useState(false)

    const handleDownload = (dom, scale) => {
        setbtnLoadings(true)
        const zip = new JSZip();
        const options = {
            useCORS: true,
            scale: 3, // 按比例增加分辨率
        };
        const promises = [];
        invoices && invoices.forEach((tab, tabIndex) => {
            promises.push(
                html2canvas(document.getElementById(`card_${tabIndex}`), options).then((canvas) => {
                    return new Promise((resolve) => {
                        const doc = new jsPDF({
                            orientation: 'l',
                            unit: 'mm',
                            format: 'a5'
                        });
                        doc.addImage(canvas.toDataURL(), "png", 0, 0, 210, 145);
                        const pdfBlob = doc.output('blob');
                        zip.file(`送货单-${tab.code}.pdf`, pdfBlob);
                        resolve();
                    });
                })
            );
        });
        Promise.all(promises).then(() => {
            zip.generateAsync({ type: 'blob' }).then((blob) => {
                saveAs(blob, `送货单-${booking_num}.zip`);
            });
            setbtnLoadings(false)
        });
    };

    return (
        <>
            {
                invoices.length !== 0 ? <div style={{ color: 'red', marginTop: '20px' }}>
                    <p>电子送货单说明:</p>
                    <p>电子送货单信息是根据供应商填写的信息生成，供应商检查信息后，</p>
                    <p>您可以在页面底部点击"下载送货单"并打印，为保证打印的送货单清晰，推荐使用如下打印方式:</p>
                    <p>1. 使用三联纸（纸张含白、粉、黄三联，纸张实际规格为单张241mm x 140mm, 或二等分241mm x 280mm）
                        电脑打印范围设置为210mm x 145mm</p>
                    <p>2. 缩放选择实际大小</p>
                    <p>3. 选择高质量打印</p>
                    <p>请在打印后盖章，或使用原有无碳纸送货单手写送货信息并盖章</p>
                </div> : null
            }

            {invoices && invoices.map((res, indexindex) => {
                return (
                    <>
                        <MaterialDetail res={res} indexindex={indexindex} key={indexindex} day={day} booking_num={booking_num} />
                    </>
                );
            })}
            {
                !isEmpty(invoices) ? <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}>
                    <Button onClick={handleDownload} type='primary' loading={btnLoadings}>下载送货单</Button>
                </div > : null
            }
        </>
    );
}

export default Material;
