import FormInformation from "page/components/formInformation"


const ModifyReservation = () => {
    const isReserve = false

    return (
        <FormInformation isReserve={isReserve} />
    )
}
export default ModifyReservation
