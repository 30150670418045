import { useNavigate } from "react-router"

const PrivacyPolicy = () => {
  const navigate = useNavigate()
  return (
    <div style={{ fontFamily: '宋体', fontSize: '12px' }}>
      <div>
        <p style={{ fontSize: '14px', orphans: '0', textAlign: 'center' }}>隐私政策</p>
        <button style={{ fontSize: '14px', float: 'right',marginTop:'-32px' }} onClick={() => navigate(-1)}>返回</button>
      </div>
      <p>
      <span>伯恩光学（惠州）有限公司及其全球的关联公司（下文简称“伯恩”、“我们”和“我们的”）深知隐私对您的重要性，并会尊重您的隐私。请在向我们提交个人信息之前，阅读、了解本《隐私政策》（下文简称“本政策”）。</span><span>您在使用</span><span>本平台</span><span>所提供的产品与/或服务前,请认真阅读并充分理解《</span><span>隐私政策</span><span>》的全部条款。</span><span>特别是其中所涉及的免除、减轻</span><span>本平台</span><span>责任的条款、对用户权利限制条款、争议解决和法律适用等。其中，限制、免责条款可能以黑体加粗形式提示</span><span>您重点</span><span>注意。</span><span>当您点击同意相关条款,并开始使用产品或服务,即表示您已经理解并同意该条款,该条款将构成对您具有法律约束力的文件。我们将通过</span><span>《隐私政策》</span><span>帮助您了解我们收集、使用、存储和共享个人信息的情况、您所享有的相关权利以及对个人信息的保护措施。</span><span>如果您不同意本政策的内容，将可能导致我们</span><span>所提供</span><span>的产品与/或服务无法正常运行，或者无法达到我们期待的服务效果，或者无法达到您期待的个人信息保护状态，对此，您应立即停止访问/使用我们的产品与/或服务。您</span><span>使用或继续使用我们提供的产品与/或服务的行为，或者您在使用或继续使用我们提供的产品与/或服务的行为之前没有以任何明示的方式向我们表达异议，都表示您充分理解和同意本</span><span>《</span><span>隐私政策</span><span>》</span><span>（包括每次更新版本）的全部内容。</span>
    </p>
    <p>
      <span>欢迎您使用我们的产品和服务</span><span>，</span><span>我们非常重视您的隐私保护和个人信息保护。本《</span><span>隐私政策</span><span>》将帮助您了解以下内容：</span>
    </p>
    <p>
      <span>一、</span><span>引言</span>
    </p>
    <p>
      <span>二、</span><span>相关定义</span>
    </p>
    <p>
      <span>三、</span><span>我们如何收集和使用您的个人信息</span>
    </p>
    <p>
      <span>(一)</span><span>主要功能及相关个人信息</span>
    </p>
    <p>
      <span>(二)</span><span>改进产品和保障用户安全的相关信息</span>
    </p>
    <p>
      <span>(三)</span><span>我们如何使用您的个人信息</span>
    </p>
    <p>
      <span>(四)</span><span>其他用途</span>
    </p>
    <p>
      <span>(五)</span><span>征得授权同意的例外
      </span>
    </p>
    <p>
      <span>四、</span><span>我们如何使用Cookie和同类技术</span>
    </p>
    <p>
      <span>五、</span><span>我们如何共享、转让、公开披露您的个人信息</span>
    </p>
    <p>
      <span>(一)</span><span>共享 </span>
    </p>
    <p>
      <span>(二)</span><span>转让</span>
    </p>
    <p>
      <span>(三)</span><span>公开披露</span>
    </p>
    <p>
      <span>(四)</span><span>例外情形</span>
    </p>
    <p>
      <span>六、</span><span>我们如何保存及保护您的个人信息</span>
    </p>
    <p>
      <span>(一)</span><span>保存信息</span>
    </p>
    <p>
      <span>(二)</span><span>保护措施</span>
    </p>
    <p>
      <span>(三)</span><span>安全事件通知</span>
    </p>
    <p>
      <span>七、</span><span>您的权利</span><span>义务</span>
    </p>
    <p>
      <span>(一)</span><span>访问和修改您的个人信息</span>
    </p>
    <p>
      <span>(二)</span><span>删除您的个人信息</span>
    </p>
    <p>
      <span>(三)</span><span>改变授权范围</span>
    </p>
    <p>
      <span>(四)</span><span>提前获知产品和服务停止运营</span>
    </p>
    <p>
      <span>(五)</span><span>响应您的上述请求</span>
    </p>
    <p>
      <span>(六)</span><span>响应请求的例外</span>
    </p>
    <p>
      <span>八、</span><span>我们如何处理未成年人的个人信息
      </span>
    </p>
    <p>
      <span>九、</span><span>您的个人信息如何进行跨境转移</span>
    </p>
    <p>
      <span>十、</span><span>《</span><span>隐私政策</span><span>》</span><span>的</span><span>更新 </span>
    </p>
    <p>
      <span>十一、</span><span>通知和</span><span>联系</span>
    </p>
    <p>
      <span>十二、</span><span>争议解决</span>
    </p>
    <p>
      <span>十三、</span><span>其他</span>
    </p>
    <p>
      <span>一、</span><span>引言</span>
    </p>
    <p>
      <span>为切实保护</span><span>本平台</span><span>用户隐私权，优化用户体验，</span><span>我们</span><span>根据现行法规及政策，制定本《</span><span>隐私政策</span><span>》。本《</span><span>隐私政策</span><span>》将详细说明</span><span>本平台</span><span>在获取、管理及保护用户个人信息方面的政策及措施。本《</span><span>隐私政策</span><span>》适用于</span><span>本平台</span><span>向您提供的所有服务，无论您是通过计算机设备、移动终端或其他</span><span>途径</span><span>所</span><span>获得的</span><span>本平台</span><span>服务。</span>
    </p>
    <p>
      <span>本《</span><span>隐私政策</span><span>》旨在帮助您了解我们会收集哪些数据、为什么收集这些数据，会利用这些数据做些什么及如何保护这些数据。我们希望您在使用</span><span>本平台</span><span>服务前仔细阅读并明确您已经充分理解、接受本《</span><span>隐私政策</span><span>》的内容，希望您可以根据自己的理解做出合适的选择。</span><span>您一旦开始使用</span><span>本</span><span>平台服务，即表示您认同本《</span><span>隐私政策</span><span>》中所述内容。在我们更新本《</span><span>隐私政策</span><span>》后，您继续使用我们的产品与/或服务，即意味着您同意本《</span><span>隐私政策</span><span>》(含</span><span>每次</span><span>更新版本)</span><span>的</span><span>内容，并且同意我们按照本《</span><span>隐私政策</span><span>》收集、使用、保存和共享您的相关信息。此外，在将您的个人信息用于本《</span><span>隐私政策</span><span>》未涵盖的用途时，我们会事先征求您的同意。</span>
    </p>
    <p>
      <span>二、</span><span>相关定义</span>
    </p>
    <p><span>本《</span><span>隐私政策</span><span>》中使用的特定词语，具有如下含义：</span></p>
    <ol>
      <li>
        <span>“我们”指</span><span>伯恩集团（包括伯恩光学（深圳）有限公司、伯恩光学（惠州）有限公司、伯恩精密（惠州）有限公司、伯恩高新科技（惠州）有限公司等伯恩体系下的公司）</span><span>。</span>
      </li>
      <li>
        <span>“关联公司”，指</span><span>伯恩光学（深圳）有限公司、伯恩光学（惠州）有限公司、伯恩精密（惠州）有限公司、伯恩高新科技（惠州）有限公司及</span><span>其他关联公司。</span>
      </li>
      <li>
        <span>“您”或“用户”，指</span><span>本</span><span>平台产品或服务的用户</span><span>（包括</span><span>公司</span><span>、</span><span>个人</span><span>）</span><span>。</span>
      </li>
      <li>
        <span>本平台PC端及本平台手机客户端</span><span>或微信小程序</span><span>。</span>
      </li>
      <li>
        <span>“个人信息”，指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括个人基本信息、个人身份信息、个人生物识别信息、网络身份标识信息、个人健康生理信息、个人教育工作信息、个人财产信息、个人通信信息、联系人信息、个人上网记录、个人常用设备信息、个人位置信息等。为免疑义，个人信息包括但不限于个人敏感信息。</span>
      </li>
      <li>
        <span>“个人信息主体”，指个人信息所标识的自然人。</span>
      </li>
      <li>
        <span>“个人敏感信息”，指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。个人敏感信息包括个人财产信息、个人健康生理信息、个人生物识别信息、个人身份信息、网络身份标识信息等。</span>
      </li>
      <li>
        <span>“去标识化”，指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。</span>
      </li>
      <li>
        <span>“匿名化”，指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。</span>
      </li>
      <li>
        <span>“中国”或“中国境内”，指中华人民共和国大陆地区，仅为本《</span><span>隐私政策</span><span>》之目的，不包含香港特别行政区、澳门特别行政区和台湾地区。</span>
      </li>
    </ol>
    <p>
      <span>三、</span><span>我们如何收集和使用您的个人信息</span>
    </p>
    <p>
      <span>(一)</span><span>主要功能及相关个人信息</span>
    </p>
    <p>
      <span>在您使用</span><span>本平台</span><span>提供的以下服务或功能过程中，我们将收集以下必要的相关个人信息。</span>
    </p>
    <ol>
      <li>
        <span>初次</span><span>访问登录</span>
      </li>
    </ol>
    <p>
      <span>1)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a
        ><span>当</span><span>您</span><span>初次</span><span>访问本平台</span><ins
          ><span>进行</span></ins><ins
          ><span>预约</span></ins><span>时</span><ins
          ><span>，</span></ins><span>您至少需要向我们提供您本人的</span><ins
          ><span>信息（</span></ins><ins
          ><span>包括但不限于</span></ins><ins
          ><span>姓名、</span></ins><span>身份证号码</span><ins
          ><span>/身份证、军官证、回乡证、护照</span></ins><ins
          ><span>、</span></ins><ins
          ><span>电话</span></ins><ins
          ><span>等）和</span></ins><ins
          ><span>到访者信息（</span></ins><ins
          ><span>包括但不限于</span></ins><ins
          ><span>姓名、</span></ins><ins
          ><span>身份证号码</span></ins><ins
          ><span>/</span></ins><ins
          ><span>军官证</span></ins><ins
          ><span>/</span></ins><ins
          ><span>回乡证</span></ins><ins
          ><span>/</span></ins><ins
          ><span>护照</span></ins><ins
          ><span>、车牌号</span></ins><ins
          ><span>、</span></ins><ins
          ><span>电话</span></ins><ins
          ><span>等</span></ins><ins
          ><span>）</span></ins><del
          ><span>和</span></del><del
          ><span>车牌号</span></del><span>。</span><span>同时</span><span>我们需要您提供您此次访问该平台所涉及的双方交易所涉及的</span><span>公司名称、</span><span>订单号、供应商编号等。</span></a>
    </p>
    <p
      >
      <span>2)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span>基于平台</span><span>服务</span><span>的更新，后续</span><span>您</span><span>将</span><span>可直接</span><span>通过第三方账户</span><span>（微信/微博</span><span>QQ/</span><span>等）</span><span>直接登录</span><span>访问</span><span>我们的产品与/</span><span>或服务</span><span>，我们</span><span>将</span><span>从第三方获取的您授权共享的账户信息（包括头像、昵称）；此外，我们会在间接收集您的个人信息前，明确以书面形式（如政策、承诺书）要求该第三方在已经向您告知其共享的信息内容并取得您的明示同意后由我们间接收集您的个人信息，且涉及您的个人敏感信息时，我们要求第三方在提供给我们使用前需经过您的再次确认，并在政策等层面要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任；同时，我们的专业安全团队对个人信息进行安全加固（包括敏感信息报备、敏感信息加密存储、访问权限控制等）。我们会使用不低于我们对直接收集的用户个人信息同等的保护手段与措施对间接收集的个人信息进行保护。</span><span>我们收集这些信息是用于为您提供账号登录服务以及保障您的账号安全，防范安全风险。如您拒绝授权此类信息的，您将无法使用第三方平台的账号登录我们平台，但不影响我们为您提供的其他产品和服务的正常使用。</span>
    </p>
    <ol start="2">
      <li>
        <span>访问</span><span>安全：</span><span>为保障您正常使用我们的服务，维护我们服务的正常运行，改进及优化我们的服务体验以及保障您的访问安全，当您使用本平台服务时，我们会收集您的设备型号、操作系统、唯一设备标识符、登陆IP地址、本平台软件版本号、接入网络的方式、类型和状态、网络质量数据、服务故障等相关信息。在本平台进行信息登录后，我们将通过本平台获取您的个人信息。</span><span>您需对使用权本平</span><span>台</span><span>所从事的所有行为及/或通过该账号所从事的所有行为承担责任。因此，您应自行负责并妥善、正确地保管、使用、维护您</span><span>在平台中所提供的所有信息。</span><span>非因我们法定过错导致的任何遗失、泄露、被篡改、被盗以及其他因保管、使用、维护不当而造成的损失，您应自行承担。</span>
      </li>
      <li>
        <span>再次访问：</span><span>基于信息验证需要，当您在初次访问后需再次或多次访问本平台时，我们可能将再次通过本平台获取您的手机号码以验证您的身份信息，为进一步向您提供服务，我们将</span><span>再度获取初次访问登录时已</span><span>提供的各项</span><span>信息。</span>
      </li>
    </ol>
    <p>
      <span>(二)</span><span>改进产品和保障</span><span>用户</span><span>安全的相关信息</span>
    </p>
    <p>
      <span>为了增强</span><span>用户在</span><span>本平台</span><span>使用</span><span>过程中的安全性，尽可能保障您或其他用户在</span><span>访问</span><span>过程中的人身、财产安全，我们会收集必要的不涉及到您的个人身份等隐私的信息，包括：</span>
    </p>
    <ol>
      <li>
        <span>我们可能会收集您的IP地址、某按钮的点击使用时间和频率、某页面的使用时间和频率、</span><span>某些关键配置的选项值、崩溃日志、错误日志，</span><span>同时有可能用于统计我们产品的用户数量、</span><span>供应商来访情况、</span><span>分析</span><span>平台</span><span>的使用状况、网页升级改版、浏览器兼容性检测、排查崩溃原因、减少崩溃情况。</span>
      </li>
      <li>
        <span>为了验证您的身份信息，防止未经授权的人员访问您的帐号，我们会在您授权同意后，获取您的手机识别码权限，访问您的手机设备型号、IP地址、设备软件版本信息、设备识别码、设备标识符、所</span><span>在地区、网络使用习惯以及与</span><span>本平台</span><span>服务相关的日志信息。否则将无法利用身份信息验证的方式保障您的账户安全。</span>
      </li>
      <li>
        <span>我们可能会利用手机的信息（包括您的手机号、邮箱地址），用于客服在接受您的咨询反馈前，会使用您的账号信息核验您的身份。</span>
      </li>
    </ol>
    <p>
      <span>(三)</span><span>我们如何使用您的个人信息</span>
    </p>
    <p><span>我们可能将您的个人信息用于以下目的：</span></p>
    <ol>
      <li>
        <span>响应您</span><span>的</span><span>访问需</span><span>求：</span><span>当您访问我们的网站，</span><span>我们将获取您的信息向您提供相应的预约服务。您也可通过访问向</span><span>我们提供意见、建议、询问等</span><span>。</span>
      </li>
      <li>
        <span>储存您</span><span>的个人信息：当您访问后，为</span><span>继续向您提供再次访问服务和我们的信息分析等需要</span><del
          ><span>响</span></del><span>，我们</span><span>将</span><span>在您访问之日起</span><span>2年内</span><span>储存</span><span>您</span><span>向我们提供的个人信息，包括您输入的</span><a
         ><span>姓名、</span><ins
            ><span>身份证号、车牌号、</span></ins><span>电话</span><span>、</span><span>公司名称、供应商</span><span>编号、订单编号、询问及回复意见</span></a><span>等</span><span>。</span>
      </li>
    </ol>
    <p>
      <span>(四)</span><span>其他用途</span>
    </p>
    <p>
      <span>在符合</span><span>《个人信息保护法》等</span><span>法律法规的前提下，</span><span>自对您的信息收集储存满2年之日起，我们</span><span>将</span><span>对</span><span>您的个人信息</span><span>进行</span><span>匿名化处理</span><span>。</span><span>当我们要将信息用于本《</span><span>隐私政策</span><span>》未载明的其他用途，将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</span>
    </p>
    <p>
      <span>(五)</span><span>征得授权同意的例外</span>
    </p>
    <p>
      <span>根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：</span>
    </p>
    <p>
      <span>1)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>与国家安全、国防安全直接相关的</span><span>。</span>
    </p>
    <p>
      <span>2)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>与公共安全、公共卫生、重大公共利益直接相关的</span><span>。</span>
    </p>
    <p>
      <span>3)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>与犯罪侦查、起诉、审判和判决执行等直接相关的</span><span>。</span>
    </p>
    <p>
      <span>4)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span>出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的</span><span>。</span>
    </p>
    <p>
      <span>5)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>所收集的个人信息是您自行向社会公众公开的</span><span>。</span>
    </p>
    <p>
      <span>6)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span>从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道</span><span>。</span>
    </p>
    <p>
      <span>7)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>根据您的要求签订和履行合同所必需的</span><span>。</span>
    </p>
    <p>
      <span>8)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span>学术研究机构基于公共利益开展统计或学术研究所必要，</span><span>且对外</span><span>提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的</span><span>。</span>
    </p>
    <p>
      <span>9)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span>用于维护</span><span>本平台</span><span>产品和/或服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障</span><span>。</span>
    </p>
    <p>
      <span>10)</span><span>&#xa0;
      </span><span>法律法规规定的其他情形</span><span>。</span>
    </p>
    <p>
      <span>如果您对我们收集和使用您的个人信息有任何疑问或需要提供进一步的信息，请通过本《</span><span>隐私政策</span><span>》公布的联系方式与我们联系。</span>
    </p>
    <p>
      <span>四、</span><span>我们如何使用Cookie和同类技术</span>
    </p>
    <p>
      <span>为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie
        通常包含用户身份标识符、城市名称</span><span>称</span><span>以及一些字符。Cookie主要的功能是便于您使用网站产品和服务，以及帮助网站统计独立访客数量等。运用Cookie技术，我们能够为您提供更加周到的服务。
        我们不会将 Cookie 用于本《</span><span>隐私政策</span><span>》所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见
        AboutCookies.org。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie
        的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置，但您可能因为该等修改，无法登录或使用依赖于Cookie的百度提供的服务或功能。
        您可以通过</span><span>更改您</span><span>的浏览器设置限制</span><span>本平台</span><span>对Cookie的使用。以Chrome浏览器为例，您可以在Chrome浏览器右上方的下拉菜单的“浏览器设置”中，通过“设置-高级-清楚浏览数据”，选择</span><span>清除您</span><span>的Cookie。</span>
    </p>
    <p>
      <span>五、</span><span>我们如何共享、转让、公开披露您的个人信息</span>
    </p>
    <p>
      <span>我们不会向第三方共享、</span><span>转让您</span><span>的个人信息，除非经过您本人事先授权同意，或者共享、转让的个人信息是去标识化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体。</span>
    </p>
    <p>
      <span>(一)</span><span>共享</span>
    </p>
    <p>
      <span>除以下情形外，未经您同意，我们不会与</span><span>我们</span><span>及其关联公司</span><span>、法院、政府部门</span><span>外的任何第三方公司</span><span>、</span><span>组织和个人分享您的信息：</span>
    </p>
    <ol>
      <li>
        <span>为实现特定功能而与业务合作伙伴共享</span>
      </li>
    </ol>
    <p>
      <span>当软件服务提供商、智能设备提供商或系统服务提供商与我们联合为您提供服务时，当您需要使用地理位置功能时，为实现这一功能，我们可能会收集您的位置信息及相关设备信息（包括硬件型号、操作系统版本号、国际移动设备身份识别码（IMEI）、网络设备硬件地址（MAC））并经“去标识化”后提供给前述提</span><span>供商。我们的授权合作伙伴包括以下类型：</span>
    </p>
    <p>
      <span>1)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span>软硬件/系统服务提供商。当第三方软硬件/系统产品或服务与</span><span>本平台</span><span>的产品或服务结合为您提供服务时，</span><span>经您授权</span><span>，我们会向第三方软硬件/系统服务提供商提供</span><span>您必要</span><span>的个人信息，以便您使用服务，或用于我们分析产品和服务使用情况，来提升您的使用体验。</span>
    </p>
    <p>
      <span>2)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span>委托我们进行信息推广和广告投放的合作伙伴</span><span>（如有）</span><span>。对于相应的信息推广和广告投放将通过您授权后展示在您所浏览的平台界面。您授权我们有权与委托我们进行信息推广和广告投放的合作伙伴共享我们使用您的相关信息集合形成的无法</span><span>识别您</span><span>的个人身份信息的间接用户画像、去标识化或匿名化处理后的分析</span><span>/统计类信息，以帮助其在不</span><span>识别您</span><span>个人身份的前提下进行广告或决策建议、提高广告</span><span>有效触</span><span>达率</span><span>、衡量广告和相关服务的有效性。当前述合作伙伴为向您提供借贷、存款等服务的金融机构（包括银行、借贷、保险、基金证券公司等机构）时，我们会另行取得您的授权，并在您授权后将您的相关信息（包括：姓名、身份证信息、通讯录信息，具体以您的授权内容为准）同步给该合作伙伴，以便协助您快速完成身份验证、输入紧急联系人信息、</span><span>风控核验</span><span>等；同时，您授权我们可以与前述金融机构共享我们使用您的相关信息集合形成的间接用户画像、去标识化或匿名化处理后的分析</span><span>/统计类信息，以帮助其</span><span>判断您</span><span>的资信状况、降低坏账率、保障商业安全和防欺诈等。我们承诺：未经您的同意，我们不会与其共享可以</span><span>识别您</span><span>身份的个人信息。为免歧义，还请您知悉，对于部分广告内容，是广告主根据您在其应用/软件内的用户习惯、偏好等用户画像进行的个性化推荐，未经您的授权或法定情形下，我们从未也不会去获取您在其他应用内的行为或个人信息。</span>
    </p>
    <ol start="2">
      <li>
        <span>我们承诺在共享过程中，尽最大可能保障您的数据和隐私不受侵害，并以不低于本《</span><span>隐私政策</span><span>》所要求的保密和安全措施来处</span><span>理该些</span><span>信息。如果</span><span>您拒绝本</span><span>条款的内容，请通过本《</span><span>隐私政策</span><span>》公布的联系方式与我们联系。</span>
      </li>
      <li>
        <span>我们与之共享个人信息的</span><span>关联公司、业务合作伙伴（如有）</span><span>，</span><span>我们在关联</span><span>公司间披露个人信息，我们仅会出于特定、明确而合法的目的在我们的关联公司内披露您的信息，并且只会披露提供服务所必要的信息。例如，</span><span>基于后续系统功能的更新升级，</span><span>在</span><span>再次</span><span>访问平台时为了避免重复登记，我们</span><span>可能</span><span>需要对</span><span>拟访问</span><span>的</span><span>帐号</span><span>进行唯一性校验，共同使用本平台的</span><span>伯恩各关联</span><span>公司将有权在您非初次访问后进行</span><span>短信</span><span>信息验证时一并获取您的所有访问记录。对于其他业务合作伙伴，</span><span>我们会与其签署严格的保密协议，并要求</span><span>其以</span><span>不低于本《</span><span>隐私政策</span><span>》所要求的保密和安全措施来处</span><span>理该些</span><span>信息。</span>
      </li>
    </ol>
    <p>
      <span>(二)</span><span>转让</span>
    </p>
    <p>
      <span>我们不会将您的个人信息转让给</span><span>我司</span><span>及其关联公司外的任何公司、组织和个人，但以下情形除外：</span>
    </p>
    <ol>
      <li>
        <span>事先获得您的明确授权或同意</span><span>。</span>
      </li>
      <li>
        <span>满足法律法规、法律程序的要求或强制性的政府要求或司法裁定</span><span>。</span>
      </li>
      <li>
        <span>如果我们或我们的关联公司涉及合并、分立、清算、资产或业务的收购</span><span>、破产重组</span><span>或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将确保该等信息在转移时的机密性，并要求新的持有您个人信息的公司、组织继续受此</span><span>隐私政策</span><span>的约束，否则我们将要求该公司、组织重新向您征求授权同意。</span>
      </li>
    </ol>
    <p>
      <span>(三)</span><span>公开披露</span>
    </p>
    <p>
      <span>我们仅会在以下情形下，公开披露您的个人信息：</span>
    </p>
    <ol>
      <li>
        <span>获得您的明确同意</span><span>。</span>
      </li>
      <li>
        <span>基于法律法规、法律程序、诉讼或政府主管部门强制性要求下。</span>
      </li>
    </ol>
    <p>
      <span>(四)</span><span>例外情形</span>
    </p>
    <p>
      <span>在以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意</span><span>。</span>
    </p>
    <ol>
      <li>
        <span>与国家安全、国防安全直接相关的</span><span>。</span>
      </li>
      <li>
        <span>与公共安全、公共卫生、重大公共利益直接相关的</span><span>。</span>
      </li>
      <li>
        <span>与犯罪侦查、起诉、审判和判决执行等直接相关的</span><span>。</span>
      </li>
      <li>
        <span>出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的</span><span>。</span>
      </li>
      <li>
        <span>您自行向社会公众公开的个人信息</span><span>。</span>
      </li>
      <li>
        <span>从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</span>
      </li>
      <li>
        <span>根据个人信息主体要求签订和履行合同所必需的</span><span>。</span>
      </li>
      <li>
        <span>用于维护所提供的产品或服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障；</span>
      </li>
      <li>
        <span>法律法规规定的其他情形。</span>
      </li>
      <li>
        <span>根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</span>
      </li>
    </ol>
    <p>
      <span>六、</span><span>我们如何保存及保护您的个人信息</span>
    </p>
    <p>
      <span>(一)</span><span>保存信息</span>
    </p>
    <ol>
      <li>
        <span>信息存储</span>
      </li>
    </ol>
    <p>
      <span>您在使用</span><span>本平台</span><span>产品及服务期间，我们将</span><span>在您访问之日起</span><span>2年内</span><span>储存您</span><span>向我们提供的个人信息</span><ins
        ><span>和到访信息</span></ins><span>，并在访问满2年内起</span><span>或您主动请求删除后对信息</span><span>进行</span><span>删除或进行</span><span>匿名化处理</span><span>，</span><span>我们</span><span>将</span><span>严格遵守《个人信息保护法》、《网络安全法》等相关法律法规的规定。</span>
    </p>
    <ol start="2">
      <li>
        <span>保存地域</span>
      </li>
    </ol>
    <p>
      <span>您的个人信息均储存于中华人民共和国境内。如部分产品或服务涉及跨境，我们需要向境外传输您的个人信息，我们会严格按照法律法规的规定执行，并保</span><span>证您的个人信息安全。</span>
    </p>
    <ol start="3">
      <li>
        <span>一般而言，我们仅</span><span>在本政策约定的2年内或仅</span><span>为实现目的所必需的时间</span><span>内</span><span>保留您的个人信息，下列情况下，我们有能因需符合法律要求，更改个人信息的存储时间：</span>
      </li>
    </ol>
    <p>
      <span>1)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>为遵守适用的法律法规等有关规定</span><span>。</span>
    </p>
    <p>
      <span>2)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>为遵守法院判决、裁定或其他法律程序的规定</span><span>。</span>
    </p>
    <p>
      <span>3)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>为遵守相关政府机关或法定授权组织的要求</span><span>。</span>
    </p>
    <p>
      <span>4)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>我们有理由确信需要遵守法律法规等有关规定</span><span>。</span>
    </p>
    <p>
      <span>5)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span>为执行相关服务协议或本《</span><span>隐私政策</span><span>》维护社会公共利益</span><span>。</span>
    </p>
    <p>
      <span>6)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span>为保护们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全</span><span>。</span>
    </p>
    <p>
      <span>7)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>其他合法权益</span><span>所合理</span><span>必需的用途。</span>
    </p>
    <ol start="4">
      <li>
        <span>当我们的产品或服务发生停止运营的情形时，我们将采取例</span><span>推送通知、公告等形式通知您，并在合理的期限内删除或匿名化处理您的个人信息。</span>
      </li>
    </ol>
    <p>
      <span>(二)</span><span> 保护措施</span>
    </p>
    <ol>
      <li>
        <span>我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL协议加密保护；我们同时对</span><span>本平台</span><span>网站提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全培训课程，加强员工对于保护个人信息重要性的认识。</span>
      </li>
      <li>
        <span>我们从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。目前，我们的重要信息系统已经通过网络安全等级保护的测评。</span>
      </li>
      <li>
        <span>我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本《</span><span>隐私政策</span><span>》所述目的所需的期限内保留您的个人信息（除非法律有强制的存留要求）。</span>
      </li>
      <li>
        <span>互联网并非绝对安全的环境，使用</span><span>本</span><span>平台</span><span>平台</span><span>服务时，我们强烈建议您不要使用非</span><span>本</span><span>平台</span><span>平台</span><span>推荐的通信方式</span><span>发送您</span><span>的信息。您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、分享时，您可以自主选择沟通、分享的对象，作为能够看到您的联络方式、交流信息或分享内容等相关信息的第三方。</span>
      </li>
      <li>
        <span>您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在</span><span>进行咨询、回复</span><span>沟通时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。我们将尽力保障您发送给我们的任何信息的安全性。</span>
      </li>
    </ol>
    <p>
      <span>(三)</span><span>安全事件通知</span>
    </p>
    <ol>
      <li>
        <span>我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。</span>
      </li>
      <li>
        <span>个人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生。若一旦不幸发生，我们将按照最高优先级启动应急预案，由安全部、政府关系部、法</span><span>务</span><span>部等多个部门组成应急响应小组，在最短时间内追溯原因并减少损失。</span>
      </li>
      <li>
        <span>在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时通过软件内部提醒、邮件、短信或电话等方式向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况通过上述方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时</span><span>，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况</span><span>。</span><span>我</span><span>们将不定期更新并公开安全风险、个人信息安全影响评估报告等有关内容，您可通过</span><span>本平台</span><span>公告、短信、系统推送等方式获得。</span>
      </li>
    </ol>
    <p>
      <span>七、</span><span>您的权利</span><span>义务</span>
    </p>
    <p>
      <span>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</span>
    </p>
    <p>
      <span>(一)</span><span>访问和</span><span>修改您</span><span>的个人信息</span>
    </p>
    <ol>
      <li>
        <span>访问修改</span><span>基础信息：</span><span>在到访前1天，您可访问系统进行取消预约服务。如后续系统更新升级，在到访前1天，您可自行访问平台进行基础信息的修改。</span>
      </li>
      <li>
        <span>如果您无法访问</span><span>修改</span><span>个人信息，您可以通过本《</span><span>隐私政策</span><span>》公布的联系方式与我们联系。</span>
      </li>
      <li>
        <ins
          ><span>后续系统更新升级中，</span></ins><span>您在使用我们的产品和服务过程中产生的其他个人信息，如果您想行使数据访问权，请通过本《</span><span>隐私政策</span><span>》的“十</span><span>一</span><span>、</span><span>通知和</span><span>联系”</span><span>中公布的联系方式与我们联系</span><span>，</span><span>我们会向您提供。</span>
      </li>
      <li>
        <span>您发现我们处理的关于您的个人信息有错误时，您有权对错误或不完整的信息</span><span>作出</span><span>更正或更新，您可以通过上述段落中列明的方式提出更正或补充申请。为保障安全，我们将在您行使更正权前对您的身份进行验证。</span>
      </li>
    </ol>
    <p>
      <span>(二)</span><span>删除您</span><span>的个人信息</span>
    </p>
    <p>
      <span>在以下情形中，您可以向我们提出删除个人信息的请求，您可以通过</span><span>“</span><span>（一</span><span>）</span><span>.访问和修改您的个人信息”中列明的方式或本《</span><span>隐私政策</span><span>》公布的联系方式随时与我们联系：</span>
    </p>
    <ol>
      <li>
        <span>如果我们违反法律法规或与您的约定收集、使用、与他人共享或</span><span>转让您</span><span>的个人信息</span><span>。</span>
      </li>
      <li>
        <span>如果我们违反法律法规规定或与您的约定，公开披露您的个人信息，您有权要求我们立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。</span>
      </li>
      <li>
        <span>我们没有征求您的明确同意，收集了您的个人信息。</span>
      </li>
      <li>
        <span>我们违反了与您的约定来使用和处理您的个人信息。</span>
      </li>
      <li>
        <span>访问结束后</span><span>您</span><span>主动请求删除相关信息</span><span>。</span>
      </li>
      <li>
        <span>我们停止对您提供服务。</span>
      </li>
    </ol>
    <p>
      <span>(三)</span><span> 改变授权范围</span>
    </p>
    <ol>
      <li>
        <span>改变或撤回敏感信息权限</span>
      </li>
    </ol>
    
    <ol>
      <li>
        <span>请您理解，特定的业务功能和服务将需要您的信息才能完成，当您撤回同意或授权</span><ins><span>（包括取消预约）</span></ins><span>后，我们无法继续为您提供撤回同意或授权所对应的服务，也将</span><span>不</span><span>再处理</span><span>您相应</span><span>的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。</span>
      </li>
    </ol>
    <p>
      <span>(四)</span><span>提前获知产品和服务停止运营</span>
    </p>
    <p>
      <span>若因特殊原因导致</span><span>本平台</span><span>被迫停止运营，我们将按照法律法规的要求，在产品和/或服务的主页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等。</span>
    </p>
    <p>
      <span>(五)</span><span>响应您</span><span>的上述请求</span>
    </p>
    <ol>
      <li>
        <span>为保障安全，您需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求</span><span>以手机短信</span><span>您验证自己的身份，然后再处理您的请求。</span>
      </li>
      <li>
        <span>对于</span><span>您合理</span><span>的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</span>
      </li>
    </ol>
    <p>
      <span>(六)</span><span>
        响应请求的例外</span>
    </p>
    <p><span>在以下情形中，按照法律法规要求，我们将无法</span><span>响应您</span><span>的上述请求：</span></p>
    <ol>
      <li>
        <span>与国家安全、国防安全直接相关的</span><span>。</span>
      </li>
      <li>
        <span>与公共安全、公共卫生、重大公共利益直接相关的</span><span>。</span>
      </li>
      <li>
        <span>与犯罪侦查、起诉、审判和执行判决等直接相关的</span><span>。</span>
      </li>
      <li>
        <span>我们有充分证据表明</span><span>您存在</span><span>主观恶意或滥用权利的（如您的请求将危害公共全和其他人</span><span>合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）</span><span>。</span>
      </li>
      <li>
        <span>响应个人</span><span>信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的</span><span>。</span>
      </li>
      <li>
        <span>涉及商业秘密的。</span>
      </li>
    </ol>
    <p>
      <span>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，</span><span>在使用本平台期间</span><span>您对自己的个人</span><span>行为须遵守以下义务</span><span>：</span>
    </p>
    <p>
      <span>1)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span>您应在遵守本协议、法律法规及其他规范性文件的前提下访问和使用我们的产品及/或服务。您同意，您不会进行或利用我们的产品及/或服务进行下列活动（也不为他人进行下列活动提供便利和支持）：</span>
    </p>
    <ol>
      <li>
        <span>以任何方式危害未成年人</span><span>（包括介绍人/经纪人通过本平台上传或使用未成年人的信息）</span><span>及/或其他人</span><span>。</span>
      </li>
      <li>
        <span>从事侵犯我们或他人合法权益的活动或违法犯罪活动，包括为上述活动提供帮助</span><span>。</span>
      </li>
      <li>
        <span>未经我们及他人（如涉及）事先明确书面许可，自行或委托他人以任何方式（包括但不限于机器人软件、蜘蛛软件、爬虫软件等任何自动程序、脚本、软件）获取平台的服务、内容、数据（包括但不限于业务数据和用户的个人信息）</span><span>。</span>
      </li>
      <li>
        <span>规避、移除、更改、阻碍、破坏或以其他方式扰乱我们用于保护我们的产品及/或服务的安全措施</span><span>。</span>
      </li>
      <li>
        <span>未经我们明确授权，采用收费或免费的方式，在任何公开场合全部或</span><span>部分展示我们的产品及/或内容（但如您的上述行为不构成侵权的除外）</span><span>。</span>
      </li>
      <li>
        <span>通过不正当手段或以违反诚实信用原则的方式（如利用规则漏洞、利用系统漏洞、滥用会员身份、黑色产业、投机等违背我们提供产品及/或服务的初衷的方式）获取、使用我们的产品或服务</span><span>。</span>
      </li>
      <li>
        <span>伪造标题或以其他方式操控识别资料，使人误认为该内容为我们所传送</span><span>。</span>
      </li>
      <li>
        <span>将软件病毒、政治宣传、商业招揽（如：广告函件、促销资料）、连锁信、大宗邮件或其他形式的“垃圾邮件”、侵犯任何人专利、商标、著作权、商业秘密或其他专属权利之内容及/或无权传送之内容（如：内部资料、机密资料）进行上载、发布、发送电子邮件或以其他方式传送</span><span>。</span>
      </li>
      <li>
        <span>利用恶意软件、网络漏洞或其他非法方式破坏我们的正常运营；窃取、盗用他人的账号及账号下财产等</span><span>。</span>
      </li>
      <li>
        <span>其他未经我们明示授权许可或违反本协议、法律法规或监管政策、侵犯我们及/或他人合法权益的行为。</span>
      </li>
    </ol>
    <p>
      <span>2)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span>您利用</span><span>我们的产品及/或服务制作、上传、复制、传送、传播的内容的行为不得违反法律法规及其他规范性文件，不得侵犯我们及/或他人的合法权益，且不得包含包括但不限于以下内容：</span>
    </p>
    <ol>
      <li>
        <span>任何反对宪法所确定的基本原则、危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一、破坏民族团结、损害国家荣誉和利益、煽动民族仇恨、民族歧视、破坏民族团结、破坏国家宗教政策、宣扬邪教和封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪、侮辱或者诽谤他人的内容或其他令人反感的包括但不限于资讯、资料、文字、软件、音乐、照片、图形、信息或其他资料</span><span>。</span>
      </li>
      <li>
        <span>广告信息，包括但不限于广告信息的标题、简介，但我们另行书面许可的除外</span><span>。</span>
      </li>
      <li>
        <span>任何侵犯我们或他人合法权益、违反法律法规及其他规范性文件（包括但不限于《网络信息内容生态治理规定》等）及本协议的内容。</span>
      </li>
    </ol>
    <p>
      <span>3)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span>后续系统升级更新后，</span><span>您对</span><span>您利用</span><span>我们的产品及/或服务制作、上传、发布、传播的符号、文字、图片、音频、视频等内容的行为，独立完整地承担法律责任，包括如造成我们的损失的，应向我们承担赔偿责任。同时：</span>
    </p>
    <ol>
      <li>
        <span>为了维护中国大陆地区及用户使用我们的产品及/或服务地区的法律法规及相关规范性文件、保护他人的合法权益、维护我们的信誉及安全，我们有权</span><span>在证实用户行为</span><span>且通知</span><span>用户后</span><span>直接删除用户利用我们产品及/或服务制作、上传、发布、传播的符号、文字、图片、音频、视频等内容</span><span>或经过与公安机关沟通后追究用户的相应责任。</span>
      </li>
      <li>
        <span>我们有权视情节严重程度对您的账号做出封禁账号，暂时停止、永久</span><span>禁止您</span><span>和相关第三方（如有）利用我们的产品及/或服务制作、上传、发布、传播的符号、文字、图片、音频、视频等内容或终止向您提供我们的产品及/或服务的处理</span><span>。</span>
      </li>
      <li>
        <span>我们的后台记录有可能作为您违反法律法规、违约、侵权的证据。</span>
      </li>
    </ol>
    <p>
      <span>八、</span><span>我们如何处理未成年人的个人信息</span>
    </p>
    <ol>
      <li>
        <span>本平台</span><span>非常重视对未成年人信息的保护。基于我们的产品、网站和服务的性质，我们不向16周岁以下的未成年人提供服务息。若您是已满16周岁不满18周岁的未成年人，建议您请您的监护人仔细阅读本《</span><span>隐私政策</span><span>》，并在征得您的监护人同意的前提下使用我们的产品和/或服务或向我们提供信息。</span>
      </li>
      <li>
        <span>如您的监护人不同意您按照本《</span><span>隐私政策</span><span>》使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。</span>
      </li>
      <li>
        <span>如果监护人发现我们在未获监护人同意的情况下收集了未成年人的个人信息，请通过本《</span><span>隐私政策</span><span>》公布的联系方式联系我们，我们会尽快删除相关数据。</span>
      </li>
    </ol>
    <p>
      <span>九、</span><span>您的个人信息如何进行跨境转移</span>
    </p>
    <p>
      <span>原则上，我们在中国境内收集和产生的个人信息仅存储在中国境内。如部分产品或服务涉及跨境，我们需要向境外传输您的个人信息，我们会严格按照法律法规的规定执行，并保证您的个人信息安全。</span>
    </p>
    <p>
      <span>十、</span><span>《</span><span>隐私政策</span><span>》</span><span>的</span><span>更新</span>
    </p>
    <ol>
      <li>
        <span>我们可能适时修订本《</span><span>隐私政策</span><span>》内容。如该等变更会导致您在本《</span><span>隐私政策</span><span>》项下权利的实质变化，我们将在变更生效前，通过系统推送、电子邮件、短信或网站公告等方式通知您。</span>
      </li>
      <li>
        <span>若您不同意该等变更应停止使用</span><span>本平台</span><span>服务，或通过本《</span><span>隐私政策</span><span>》公布的联系方式要求暂时</span><span>封停您的</span><span>账号。若您继续使用我们的产品或服务，即表示您同意受修订后的本《</span><span>隐私政策</span><span>》的约束；本《</span><span>隐私政策</span><span>》所指的重大变更包括但不限于：</span>
      </li>
    </ol>
    <p>
      <span>1)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span>我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等</span><span>。</span>
    </p>
    <p>
      <span>2)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>个人信息共享、转让或公开披露的主要对象发生变化</span><span>。</span>
    </p>
    <p>
      <span>3)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>您参与个人信息处理方面的权利及其行使方式发生重大变化</span><span>。</span>
    </p>
    <p>
      <span>4)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>其他可能对您的个人信息权益产生重大影响的变化时</span><span>。</span>
    </p>
    <p>
      <span>5)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>个人信息出境情况发生变更时。</span>
    </p>
    <ol start="3">
      <li>
        <span>更新后的协议于</span><span>您同意更新之日起在双方间</span><span>生效。</span>
      </li>
    </ol>
    <p>
      <span>十一、</span><span>通知和</span><span>联系</span>
    </p>
    <ol>
      <li>
        <span>本协议项下我们对于用户所有的通知均可通过网页公告、站内信、电子邮件、手机短信或常规的信件传送等方式进行，该等通知于发送之日视为已送达您。</span>
      </li>
      <li>
        <span>如您对本协议或使用我们的产品与/或服务相关的事宜有任何问题（包括问题咨询、投诉等），我们专门为您提供了多种反馈通道，我们会在收到</span><span>您的反馈后尽快向您答复。</span>
      </li>
    </ol>
    <p>
      <span>1)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span>专设的邮件通道：我们专门设立了datalab-fastpass@bielcrystal.com邮箱，您可以通过该邮箱与我们联系。</span>
    </p>
    <p>
      <span>2)</span><span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>其他方式：我们提供的其他反馈通道。</span>
    </p>
    <p>
      <span>十二、</span><span>争议解决</span>
    </p>
    <p>
      本《隐私政策》构成您使用本平台服务的基本协议文件。本《隐私政策》适用中华人民共和国现行法律法规。与本《隐私政策》相关的争议，如果您对我们的回复或解决方案不满意，双方可协商解决，协商不成需要通过法律途径解决的，应将该争议提交伯恩所在地有管辖权的法院诉讼解决。
    </p>
    <p>
      <span>十三、</span><span>其他</span>
    </p>
    <ol>
      <li>
        <span>本协议的标题仅为方便阅读而设，不影响本协议任何条款的含义或解释。</span>
      </li>
      <li>
        <span>在任何情况下，本协议中包含的任何一个或多个条款被认为无效、非法或不可执行，不影响其余条款的有效性、合法性和</span><span>可</span><span>执行性。</span>
      </li>
      <li>
        <span>本协议自您同意并接受之日起即在双方间生效</span><span>，更新的协议自更新且您同意之日起</span><span>生效。更新的协议</span><span>构成您使用本平台服务的基本协议文件</span><span>的部分，与原协议具有同等法律效力。</span>
      </li>
    </ol>
    </div>
  )
}
export default PrivacyPolicy