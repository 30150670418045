import Style from 'styled-components'

const Styles = Style.div`
  .title {
    color:green;
    font-size:26px;
  }
  .main {
    position:relation;
  }
  .main-content {
    position: absolute;
    top:50%;
    left:50%;
    -webkit-transform:translate(-50%,-50%);
  }
`

export default Styles