import { Button, Checkbox, Col, Divider, Form, Input, Row, Select, Spin, message } from "antd";
import { addAppointment, getCorrespondData, getMetadatas, updateBookings } from 'api/index';
import { cloneDeep, isEmpty, isNull } from 'lodash';

import moment from 'moment';
import 'moment/locale/zh-cn';
import BasicsFrom from 'page/basicsForm';
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getNewlineValue } from 'utils/util';
import { GlobalMetadataContext } from '../../contexts/GlobalMetadataContext';
import OrderForm from '../orderForm';
import PersonInfoMation from "./personnelInformation";
import Styles from "./style.js";

const { Option } = Select


const FormInformation = (props, { onChange, fields }) => {
    const { isReserve } = props
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const [IDCardType, setIDCardType] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isSelectCheckbox, setIsSelectCheckbox] = useState(false)

    const [visitor_infos, setVisitor_infos] = useState([
        {
            name: "",
            passport_num: "",
            phone_num: "",
            passport_area_id: "",
            plate_num: "",
            passport_type_id: ''
        },
    ])

    const [warehouseFlag, setWarehouseFlag] = useState(false)
    const [verifySupplierNumberAndOrderFlag, setVerifySupplierNumberAndOrderFlag] = useState(false)
    const [businessesData, setBusinessesData] = useState({})
    const [showPurchase, setShowPurchase] = useState(true)
    const [timeTableData, setTimeTableData] = useState({ groups: [], items: [] })
    const [selectProcess, setSelectProcess] = useState()
    const { layout, offset, setCurrent } = useContext(GlobalMetadataContext)
    const [modifyProcessData, setModifyProcessData] = useState()
    const right_layout = { xs: 24, sm: 24, md: 24 }
    const [bottomLayout, SetbottomLayout] = useState({ left: 18, right: 4 })

    const [isEmergent, setIsEmergent] = useState(false) // 是否是紧急流程 默认是false
    const [isSample, setSample] = useState(false) // 是否包含样品 默认是false
    const [isWorkDay, setIsWorkDay] = useState(true) // 是否属于工作日 默认是true
    const [selectProcessOneDay, setSelectProcessOneDay] = useState() // 是否属于工作日
    const [selectProcessObj, setSelectProcessObj] = useState({})

    useEffect(() => {
        if (isReserve) {
            setCurrent('#/')
            fillTheNewData()
        } else {
            fillTheModificationData()
        }
        setIsSelectCheckbox(false)
    }, [isReserve])

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 820px)'); // 检测屏幕宽度是否小于等于768像素
        function handleMediaQueryChange(e) {
            if (e.matches) {
                SetbottomLayout({ left: 24, right: 24 })
            } else {
                SetbottomLayout({ left: 18, right: 4 })
            }
        }

        handleMediaQueryChange(mediaQuery); // 初始化时调用一次
        mediaQuery.addListener(handleMediaQueryChange); // 监听屏幕宽度变化
        // 默认是否紧急是否包含样品
        let initFromvalues = form.getFieldsValue()
        initFromvalues.business_process.including_samples = false
        initFromvalues.business_process.is_urgent = false
        form.setFieldsValue({ ...initFromvalues })
        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);// 组件卸载时移除监听器 
        };
    }, []);

    // 实时更新预约表单数据
    const changeData = (value) => {
        const formValues = form.getFieldsValue()
        if (!isReserve) {
            sessionStorage.setItem("modifyData", JSON.stringify(formValues))
        } else {
            sessionStorage.setItem("bookingData", JSON.stringify(formValues))
        }
    }

    useEffect(() => {
        const formValues = form.getFieldsValue()
        if (formValues) {
            if (selectProcess !== undefined) {
                formValues.business_process.order_nums = ''
                formValues.business_process.supplier_num = ''
                form.setFieldsValue({ ...formValues })
                sessionStorage.setItem("bookingData", JSON.stringify(formValues))
            }
        }

    }, [verifySupplierNumberAndOrderFlag])


    // 是否勾选
    const ChangeCheckbox = (value) => {
        setIsSelectCheckbox(value.target.checked)
    }

    // 点击跳转查看详情
    const privacyContent = () => {
        navigate('/privacy/policy')
    }

    // 新增预约数据填入表单
    const fillTheNewData = async () => {
        if (sessionStorage.getItem("bookingData") !== null) {
            setLoading(true)
            const data = JSON.parse(sessionStorage.getItem("bookingData"))
            let correspondData = []
            const defaultDate = data.day ? moment(data.day) : null
            let momentTime = []
            if (data.time) {
                data.time.map(res => {
                    momentTime.push(moment(res))
                })
                data.time = momentTime
            } else {
                data.time = ''
            }
            correspondData = await getCorrespondData('00000001')
            setBusinessesData(correspondData)
            if (data.day) {
                setSelectProcessOneDay(moment(data.day).format("YYYY-MM-DD"))
                console.log(data.business_process.process_strategy_id, '==data.business_process.process_strategy_id==');
                correspondData.process_strategies && correspondData.process_strategies.map(res => {
                    if (res.id == data.business_process.process_strategy_id) {
                        setSelectProcessObj(res)
                    }
                })
            }
            if (data) {
                form.setFieldsValue({ ...data, day: defaultDate })
            }
            setLoading(false)
            return setVisitor_infos([...data.visitor_infos])
        }
    }

    // 修改预约数据填入表单
    const fillTheModificationData = async () => {
        console.log("fillTheModificationData");
        setLoading(true)
        const getmetaDatas = await getMetadatas()
        if (sessionStorage.getItem("modifyData") !== null) {
            const modifyData = JSON.parse(sessionStorage.getItem("modifyData"))
            modifyData.day = modifyData.day ? moment(modifyData.day) : ''
            let momentTime = [moment(modifyData.time[0]), moment(modifyData.time[1])]
            modifyData.time = momentTime

            if (modifyData) {
                modifyData && modifyData.visitor_infos.map(res => {
                    const { acceptable_passports } = getmetaDatas;
                    const newData = acceptable_passports.filter((type) => type.id === res.passport_area_id)
                    const acceptablePassports = cloneDeep(newData)
                    if (acceptablePassports.length !== 0) {
                        setIDCardType(acceptablePassports[0].passport_types)
                    }
                })
                if (modifyData.invoices) {
                    modifyData.purchase_orders = modifyData.invoices
                }
                const correspondData = await getCorrespondData('00000001')
                if (modifyData.day) {
                    setSelectProcessOneDay(moment(modifyData.day).format("YYYY-MM-DD"))
                    correspondData.process_strategies && correspondData.process_strategies.map(res => {
                        if (res.id == modifyData.business_process.process_strategy_id) {
                            setSelectProcessObj(res)
                        }
                    })
                }

                const tempModifyData = arrangeProcess(modifyData)

                form.setFieldsValue({ ...tempModifyData })
                setLoading(false)
                return setVisitor_infos([...tempModifyData.visitor_infos])
            }
            form.setFieldsValue({ ...modifyData })
            return setVisitor_infos([...modifyData.visitor_infos])
        }

    }

    const getProcessByMoreCondition = () => {
        // 是否是工作日
        businessesData.workdays && businessesData.workdays.map(res => {
            if (selectProcessOneDay == res.day) {
                setIsWorkDay(res.day_type == 0 ? false : true)
            }
        })
        // 紧急流程 那么供应商编号 送货单号就是必填
        if (isEmergent) {
            setVerifySupplierNumberAndOrderFlag(false);
            setShowPurchase(true);
            setSelectProcessObj({ name: '紧急流程', id: 's0000000000000000004' })
            return
        }
        // 正常流程
        if (isWorkDay && !isSample) {
            setVerifySupplierNumberAndOrderFlag(true);
            setShowPurchase(false);
            setSelectProcessObj({ name: '正常流程', id: 's0000000000000000001' })
            return
        }
        // 样品流程
        if (isWorkDay && isSample) {
            setVerifySupplierNumberAndOrderFlag(false);
            setShowPurchase(true);
            setSelectProcessObj({ name: '样品流程', id: 's0000000000000000002' })
            return

        }
        // 非工作日流程
        if (!isEmergent && !isSample && !isWorkDay) {
            setVerifySupplierNumberAndOrderFlag(true);
            setShowPurchase(false);
            setSelectProcessObj({ name: '非工作日流程', id: 's0000000000000000003' })
            return
        }
        // 非工作日样品流程
        if (!isWorkDay && isSample) {
            setVerifySupplierNumberAndOrderFlag(false);
            setShowPurchase(true);
            // setSelectProcessObj({ name: '非工作日样品流程', id: 's0000000000000000005' })
            setSelectProcessObj({ name: '紧急流程', id: 's0000000000000000004' })
            return
        }
    }


    useEffect(() => {
        getProcessByMoreCondition()
    }, [isEmergent, isSample, isWorkDay, selectProcessOneDay])


    useEffect(() => {
        const tempFormValues = form.getFieldsValue()
        tempFormValues.business_process.process_strategy_id = selectProcessObj?.id
        //  处理流程
        form.setFieldsValue({ ...tempFormValues })
    }, [selectProcessObj])


    // 是否紧急 是否包含样品 赋值
    const changeBySelect = (vale, type) => {
        switch (type) {
            case 'is_urgent':
                setIsEmergent(vale)
                break;
            case 'including_samples':
                setSample(vale)
                break;
            default:
                break;
        }
    }

    // 整理流程
    const arrangeProcess = (arrangeProcessData) => {
        const { business_process } = arrangeProcessData
        switch (business_process.process_strategy_id) {
            case 's0000000000000000001':
                business_process.is_urgent = false;
                business_process.including_samples = false;
                break;
            case 's0000000000000000002':
                business_process.is_urgent = false;
                business_process.including_samples = true;
                setSample(true)
                break;
            case 's0000000000000000003':
                business_process.is_urgent = false;
                business_process.including_samples = false;
                break;
            case 's0000000000000000004':
                business_process.is_urgent = true;
                business_process.including_samples = false;
                setIsEmergent(true)
                break;
                // case 's0000000000000000005':
                //     business_process.is_urgent = false;
                //     business_process.including_samples = true;
                //     setSample(true)
                break;
            default:
                break;
        }
        return arrangeProcessData
    }


    // 提交数据
    const submit = async (value) => {
        console.log(value, '==value==');
        let visitors = value.visitor_infos

        let passportNumArr = []
        let visitorLength = visitors && visitors.length ? visitors.length : 0
        for (let i = 0; i < visitorLength; i++) {
            if (visitors[i].plate_num === '') {
                delete visitors[i].plate_num
            }
            passportNumArr.push(visitors[i].passport_num)
        }
        const data = [...new Set(passportNumArr)]
        if (passportNumArr.length !== data.length) {
            message.error('证件号码相同，请重新修改信息')
            throw new Error()
        }

        if (value?.business_process?.order_nums) {
            if (typeof value.business_process.order_nums !== 'string') {
                value.business_process.order_nums = value.business_process.order_nums.toString();
            }
            const { tempArr, flag } = getNewlineValue(value.business_process.order_nums)
            value.business_process.order_nums = tempArr
            if (!flag) {
                message.error('订单号格式错误，请重新修改信息')
                throw new Error()
            }
        }

        if (value?.business_process?.supplier_name === '') {
            delete value.business_process.supplier_name
        }

        if (value?.business_process?.supplier_num === '') {
            delete value.business_process.supplier_num
        }
        value.department_id = 'd0000000000000000003'

        value.business_process.process_strategy_id = selectProcessObj.id;

        let is_correctly = []
        const handle_purchase_orders = value.purchase_orders && value.purchase_orders.filter(obj => {
            const hasCode = obj.hasOwnProperty('code') && !isEmpty(obj.code)
            const poItems = obj.po_items || []
            const hasSeqQuantity = poItems.some(item => {
                let seqFlag = item.hasOwnProperty('seq') && !isNull(item.seq)
                let quantityFlag = item.hasOwnProperty('quantity') && !isNull(item.quantity)
                if (seqFlag == false && quantityFlag == false && hasCode == false) {
                    is_correctly.push(true)
                    return true
                } else if (seqFlag && quantityFlag && hasCode) {
                    is_correctly.push(true)
                    return true
                } else {
                    is_correctly.push(false)
                    return false
                }
            })
            return hasCode && hasSeqQuantity
        }).map(obj => ({
            po_items: obj.po_items.filter(item => item.seq && item.quantity),
            code: obj.code
        }))
        console.log(is_correctly, '==is_correctly==');
        if (is_correctly.includes(false)) {
            message.error("请完善订单号，若不需要填写请清空订单号的信息")
            throw new Error()
        }
        if (!isEmpty(handle_purchase_orders)) {
            value.business_process.order_nums = handle_purchase_orders.map(po => po.code)
            value.purchase_orders = handle_purchase_orders
            if (isEmpty(value.business_process.supplier_num)) {
                message.error("请填写供应商编号")
                throw new Error()
            }
        } else {
            delete value.purchase_orders
        }
        if (isSelectCheckbox) {
            setLoading(true)
            if (isReserve) {
                if (value.time) {
                    value.started_time = moment(value.time[0]).format("HH:mm")
                    value.ended_time = moment(value.time[1]).format("HH:mm")
                    value.day = moment(value.day).format("YYYY-MM-DD")
                    value.process_type_id = '00000001'
                    if (value.ended_time === '12:30' || value.ended_time === '21:30') {
                        message.error('当前选择的结束时间是休息时间，请按照下方的流程说明选择时间')
                        throw new Error()
                    }
                    const flag2 = moment(`${value.day},${value.started_time}`).diff(moment(), 'minutes')
                    if (flag2 < 0) {
                        message.error('不能预约过去的时间,请重新选择正确的时间')
                        throw new Error()
                    }
                }
                console.log("提交的数据:", value)
                try {
                    let responseData = await addAppointment([value])
                    responseData[0].process_strategy_name = selectProcessObj.name
                    message.success("新增预约成功")
                    sessionStorage.setItem("appointmentDetailsData", JSON.stringify(responseData))
                    navigate({ pathname: '/appointment/details/add' })
                    const deleteOtherBookingData = JSON.parse(sessionStorage.getItem("bookingData"))
                    const { business_process, day, time, park_id, purchase_orders, ...newObj } = deleteOtherBookingData
                    sessionStorage.setItem("bookingData", JSON.stringify(newObj))
                } catch (e) {
                    if (e.data.detail) {
                        message.error(e.data.detail.res_msg)
                    } else {
                        message.error('新增预约失败请检查后重试')
                    }
                } finally {
                    setLoading(false)
                }

            } else {
                value.started_time = moment(value.time[0]).format("HH:mm")
                value.ended_time = moment(value.time[1]).format("HH:mm")
                value.day = moment(value.day).format("YYYY-MM-DD")
                const { id, businessProcessId } = JSON.parse(sessionStorage.getItem("business_processId"))
                value.business_process.id = businessProcessId
                value.id = id
                value.process_type_id = '00000001'
                try {
                    let updateData = await updateBookings([value])
                    updateData[0].process_strategy_name = selectProcessObj.name
                    message.success("修改预约成功")
                    sessionStorage.setItem("appointmentDetailsData", JSON.stringify(updateData))
                    navigate({ pathname: '/appointment/details/modify' })
                    sessionStorage.removeItem("modifyData")
                } catch (e) {
                    if (e.data.detail.res_msg) {
                        message.error(e.data.detail.res_msg)
                    } else {
                        message.error('修改预约失败请检查后重试')
                    }
                } finally {
                    setLoading(false)
                }
            }
        }
    }

    return (
        <Styles>
            <Spin size="large" spinning={loading}>
                <Form
                    name="user_form"
                    form={form}
                    fields={fields}
                    initialValues={{ visitor_infos: visitor_infos }}
                    onFinish={submit}
                    onValuesChange={changeData}
                >
                    <div className="content">
                        <Form.Item>
                            {
                                visitor_infos.map((item, index) => {
                                    return <PersonInfoMation
                                        key={index}
                                        item={item}
                                        index={index}
                                        visitorInfos={visitor_infos}
                                        setVisitorInfos={setVisitor_infos}
                                        fillTheNewData={fillTheNewData}
                                        IDCardType={IDCardType}
                                        setIDCardType={setIDCardType}
                                        fillTheModificationData={fillTheModificationData}
                                        isReserve={isReserve}
                                        form={form}
                                    />
                                })
                            }
                        </Form.Item>
                        <Divider plain>可填写多个成员</Divider>

                        <BasicsFrom
                            businessesData={businessesData}
                            setBusinessesData={setBusinessesData}
                            setWarehouseFlag={setWarehouseFlag}
                            isReserve={isReserve}
                            warehouseFlag={warehouseFlag}
                            timeTableData={timeTableData}
                            setTimeTableData={setTimeTableData}
                            form={form}
                            verifySupplierNumberAndOrderFlag={verifySupplierNumberAndOrderFlag}
                            setVerifySupplierNumberAndOrderFlag={setVerifySupplierNumberAndOrderFlag}
                            setLoading={setLoading}
                            setSelectProcessOneDay={setSelectProcessOneDay}
                            selectProcessOneDay={selectProcessOneDay}
                        />
                        <Divider plain className="divider"></Divider>
                        <Row>
                            <Col layout={{ md: 12, sm: 24, xs: 24 }} span={bottomLayout.left}>
                                <>
                                    <Row>
                                        <Col {...layout} >
                                            <Form.Item
                                                label="是否紧急"
                                                name={["business_process", "is_urgent"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入是否紧急'
                                                    }
                                                ]}
                                                labelCol={{ style: { width: 90 } }}
                                            >
                                                <Select onChange={(e) => { changeBySelect(e, 'is_urgent') }} disabled={!isReserve}>
                                                    <Option value={true} key="true">是</Option>
                                                    <Option value={false} key="false">否</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col {...layout} offset={offset}>
                                            <Form.Item
                                                label="是否包含样品"
                                                name={["business_process", "including_samples"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入是否包含样品'
                                                    }
                                                ]}
                                            >
                                                <Select onChange={(e) => { changeBySelect(e, 'including_samples') }} disabled={!isReserve}>
                                                    <Option value={true} key="true">是</Option>
                                                    <Option value={false} key="false">否</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col {...layout} offset={offset}>
                                            <Form.Item
                                                label={
                                                    <span style={{ fontWeight: 'bold', color: 'red' }}>流程</span>
                                                }
                                                name={["business_process", "process_strategy_id"]}
                                            >
                                                <Select disabled={true}>
                                                    <Option value={selectProcessObj.id} key={selectProcessObj.id} >{selectProcessObj.name}</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col {...layout}>
                                            <Form.Item
                                                label="供应商名称"
                                                name={["business_process", "supplier_name"]}
                                                rules={[{ required: true, message: "请输入供应商名称" }]}
                                            >
                                                <Input maxLength={50} disabled={!isReserve} />
                                            </Form.Item>
                                        </Col>
                                        <Col {...layout} offset={offset}>
                                            <Form.Item
                                                label="供应商编号"
                                                name={["business_process", "supplier_num"]}
                                                rules={[
                                                    { required: verifySupplierNumberAndOrderFlag, message: "请输入供应商编号" },
                                                    {
                                                        required: false, pattern: new RegExp(/^[0-9a-zA-Z\\-]{1,32}$/, "g"),
                                                        message: '请输入正确供应商编号'
                                                    }
                                                ]}
                                                extra="例:BL-123456"
                                            >
                                                <Input maxLength={50} disabled={isReserve ? false : true} />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <div className='order-form'>
                                        <div style={{ fontSize: '10px', lineHeight: '12px', color: 'red' }}>
                                            <p>订单信息填写说明:</p>
                                            <p>1. 请确认: 订单号是否对应填写的供应商编号</p>
                                            <p>2. 请与伯恩采购确认: 订单号是否已经发出采购单, 采购单是否仍剩余在外量</p>
                                            <p>3. 订单项次的数量校验规则: 本次填写的数量，与未完成送货的预约(状态为待审批、已审批、交接中)对应同订单、同项次的数量之和，不可大于在外量
                                            </p>
                                            <p>如: 某供应商预约送货订单号GPBA-123的1号项次货物，该供应商使用此项次预约了5次，有2次预约尚未完成，预约送货数量分别为10，15，在外量剩余100，</p>
                                            <p>那么，
                                                本次预约送货订单号GPBA-123的1号项次货物填写的数量值不可超过 100 - 10 - 15 = 75</p>
                                            <p>4. 紧急流程暂时不会根据填写的送货信息生成送货单</p>
                                        </div>
                                        <OrderForm layout={layout} verifySupplierNumberAndOrderFlag={verifySupplierNumberAndOrderFlag} />
                                    </div>
                                </>
                            </Col>
                            <Col layout={{ md: 4, sm: 24, xs: 24 }} span={bottomLayout.right}>
                                <Row>
                                    <Col {...right_layout}>
                                        <Form.Item
                                            label="采购组别"
                                            name={["business_process", "purchasing_group_id"]}
                                            labelCol={{ style: { width: 90 } }}
                                            rules={[{ required: showPurchase, message: "请输入采购组别" }]}
                                        >
                                            <Select disabled={!isReserve}>
                                                {
                                                    businessesData.purchasing_groups && businessesData.purchasing_groups.map(res => {
                                                        return <Option value={res.id} key={res.id}>{res.name}</Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col {...right_layout}>
                                        <Form.Item
                                            label="柴油叉车"
                                            name={["business_process", "need_fork_truck"]}
                                            rules={[{ required: true, message: "请输入是否需要柴油叉车" }]}
                                            labelCol={{ style: { width: 90 } }}
                                        >
                                            <Select >
                                                <Option value={true}>是</Option>
                                                <Option value={false}>否</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col  {...right_layout} >
                                        <Form.Item
                                            label="备注"
                                            name={["business_process", "notes"]}
                                            labelCol={{ style: { width: 90 } }}
                                        >
                                            <Input.TextArea maxLength={200} disabled={!isReserve} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <footer className="footer">
                        <Checkbox onChange={ChangeCheckbox} style={{ marginRight: '10px' }}></Checkbox><a onClick={privacyContent}>我已阅读并同意《伯恩访客预约平台隐私政策》</a>
                        <Button type={isSelectCheckbox ? 'primary' : ""} htmlType="submit" size="large" disabled={isSelectCheckbox ? false : true} className="submit" onClick={submit}>
                            提交
                        </Button>
                    </footer>
                </Form>
            </Spin>
        </Styles >
    )
}

export default FormInformation
