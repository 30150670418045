import { Button, Card } from "antd";
import { isEmpty } from 'lodash';
import Material from 'page/components/material';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AppointmentStyles from "./style";

const AppointmentDetails = (props) => {
  const [detail, setDetail] = useState({ booking_num: '', name: [], invoices: [], process_strategy_name: '' })
  const navigate = useNavigate()

  useEffect(() => {
    const appointmentDetailsData = JSON.parse(sessionStorage.getItem("appointmentDetailsData"))
    let temp = { booking_num: '', name: [] }
    let name = []
    if (appointmentDetailsData) {
      const completeData = appointmentDetailsData[0]
      temp.booking_num = completeData.booking_num
      temp.process_strategy_name = completeData.process_strategy_name
      completeData.visitor_infos.map(res => {
        name.push(res.visitor_info.visitor.name)
        let str = '';
        for (let i = 0; i < name.length; i++) {
          str += name[i] + ',' + ' ';
        }
        temp.name = str
      })
      temp.invoices = completeData.invoices
      setDetail(temp)
    }
  }, [])

  const getBack = () => {
    const flag = window.location.href.includes('modify') ? navigate('/search') : window.history.back(-1)
  }

  return (
    <AppointmentStyles>
      <div className="btn">
        <Button className="mock-block" type="primary" onClick={getBack} >返回上一页</Button>
      </div>
      <Card className="booking-card">
        预约已经提交到后台，预约编号为
        <span className="number">{detail.booking_num}</span>的
        <span className="strategy">{detail.process_strategy_name}</span>
        预约审批结束后，来访人员[ <span className="member">{detail.name}</span> ]将收到短信通知，请来访人员注意查收
        来访人员也可以在微信上使用<span className="program">《伯恩访客》小程序</span>，输入身份证后查询预约信息。请妥善保存预约编号，以便后续通过网页查询预约记录。
      </Card>
      {
        isEmpty(detail.invoices) ? <></> : (<Material />)
      }
    </AppointmentStyles>
  )
}

export default AppointmentDetails