import Style from 'styled-components'

const Styles = Style.div`
@media screen (max-width: 450px) {
  .parent {
    width: 100%;
    height: 600px;
    margin: 0 auto;
  }
  .child{
    top: 15%;
    width: 100%;
    height: 200px;
  }
}
@media screen and (min-width: 550px){
  .parent {
    position: relative;
    width: 70%;
    height: 600px;
    margin: 0 auto;
  }
  .child{
    position: absolute;
    top: 15%;
    width: 80%;
    height: 200px;
  }
  .submit {
    left:35%;
    top:20px
  }

}

.mock-block {
  position:absolute;
  right:40px;
}
.mock-block-cancel {
  position:absolute;
  right:140px;
}

`

export default Styles