import * as Sentry from "@sentry/react";
import { OPEN_SENTRY_R_R_WEB_TRACE } from "router/router";
import request from '../server/service'

const startTransaction = (transactionName, data) => {
  const transaction = Sentry.startTransaction({ bindToScope: true, name: transactionName });
  Sentry.getCurrentHub().configureScope(scope => scope.setSpan(transaction));
  const span = transaction.startChild({
    data: { data },
    op: 'HTTP',
    description: `request`,
  });
  return { transaction, span }
}

const finishTransaction = (span, transaction, data, requestResponse) => {
  if (OPEN_SENTRY_R_R_WEB_TRACE) {
    span.setTag("attachments", "video")
    transaction.setTag("attachments", "video")
  }
  const dataString = JSON.stringify(data)
  const requestResponseString = JSON.stringify(requestResponse)
  span.setData("data", dataString)
  span.setData("response", requestResponseString)
  span.finish()
  transaction.setHttpStatus(requestResponse?.status)
  transaction.setData("data", dataString)
  transaction.setData("response", requestResponseString)
  transaction.name = requestResponse?.config?.url
  transaction.finish()
}

const createTransaction = func => async (data) => {
  let errInRequest
  let requestResponse
  try {
    const { transaction, span } = startTransaction(func.name, data)

    try {
      const response = await func(data)
      requestResponse = response
    } catch (err) {
      console.error("err in request")
      console.error(err)
      errInRequest = err
      requestResponse = err
    }

    finishTransaction(span, transaction, data, requestResponse)
  } catch (err) {
    console.error("err in sentry transaction")
    console.error(err)
  } finally {
    if (errInRequest) {
      throw errInRequest
    } else {
      return requestResponse.data
    }
  }
}


// 获取原数据 
const getMetadatasReq = () => {
  return request({
    method: 'GET',
    url: '/metadatas',
  })
}
export const getMetadatas = createTransaction(getMetadatasReq)

// 新建预约信息
const addAppointmentReq = (data) => {
  return request({
    method: 'POST',
    url: '/bookings',
    data
  })
}
export const addAppointment = createTransaction(addAppointmentReq)


// 根据查询条件预约信息
const filterAppointmentDataReq = (data) => {
  return request({
    method: 'POST',
    url: '/bookings/filter',
    data
  })
}
export const filterAppointmentData = createTransaction(filterAppointmentDataReq)


// 取消预约 
const deleteBookingsReq = data => {
  const { bookingNum: booking_num, newArr } = data
  return request({
    method: 'DELETE',
    url: `/bookings/${booking_num}`,
    data: newArr
  })
}
export const deleteBookings = createTransaction(deleteBookingsReq)


// 更新预约
const updateBookingsReq = (data) => {
  return request({
    method: 'PUT',
    url: '/bookings',
    data
  })
}
export const updateBookings = createTransaction(updateBookingsReq)


// 获取对应业务的元数据
const getCorrespondDataReq = (data) => {
  return request({
    method: 'GET',
    url: `/businesses/${data}/metadatas`,
  })
}
export const getCorrespondData = createTransaction(getCorrespondDataReq)


// 时间表
const getTimetableReq = data => {
  const { selectedArea: area_id, selectedDay: day } = data
  return request({
    method: 'GET',
    url: `/businesses/00000001/timetable?area_id=${area_id}&day=${day}`,
  })
}
export const getTimetable = createTransaction(getTimetableReq)

