import { Spin } from 'antd';
import moment from "moment";
import { useEffect, useState } from "react";
import Timeline from "react-calendar-timeline/lib";
import 'react-calendar-timeline/lib/Timeline.css';
import { StrategiesTimelineContainer } from "./style";

export const StrategiesTimeline = ({ timeTableData: newTimeTableData, selectOneDay }) => {
  const [visibleTimeStart, setVisibleTimeStart] = useState(moment(selectOneDay))
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(moment(selectOneDay))
  const [spin, setSpin] = useState(false)

  useEffect(() => {
    if (newTimeTableData) {
      setSpin(false)
    } else {
      setSpin(true)
    }
  }, [newTimeTableData])

  useEffect(() => {
    setVisibleTimeStart(moment(selectOneDay).startOf("day").add(parseInt(8), "hour").toDate())
    setVisibleTimeEnd(moment(selectOneDay).startOf("day").add(parseInt(21), "hour").toDate())
  }, [selectOneDay])

  return (
    <StrategiesTimelineContainer style={{ marginTop: '30px', }}>
      <Spin spinning={spin}>
        {
          newTimeTableData.groups && newTimeTableData.groups.length !== 0 ? <div>
            <div style={{ width: '60px', height: '35px', border: '1px solid #eee', float: 'left', background: 'linear-gradient(337deg, #f0f0f0 0, #f0f0f0 50.5%, #f0f0f0 50.5%, #aaa 50.5%, #f0f0f0 54%, #f0f0f0 50.5%)' }}></div>
            <p style={{ float: 'left', margin: '4px 0px 10px  0px' }}>不能预约的时间</p>
            <Timeline
              groups={newTimeTableData.groups ? newTimeTableData.groups : []}
              items={newTimeTableData.items ? newTimeTableData.items : []}
              canMove={false}
              sidebarWidth={80}
              visibleTimeStart={visibleTimeStart}
              visibleTimeEnd={visibleTimeEnd}
            />
          </div> : <></>
        }
      </Spin>
    </StrategiesTimelineContainer>

  )
}