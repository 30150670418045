import styled from 'styled-components'

export const StrategiesTimelineContainer = styled.div`
.react-calendar-timeline .rct-header-root {
  background: #D9D9D9 !important;
}
.react-calendar-timeline .rct-sidebar{
  border: none !important;
  text-align:center
}
.react-calendar-timeline .rct-dateHeader-primary {
  color:black
}
.rct-item {
  background: linear-gradient(337deg, #f0f0f0 0, #f0f0f0 50.5%, #f0f0f0 50.5%, #aaa 50.5%, #f0f0f0 54%, #f0f0f0 50.5%) !important;
  border:1px solid #bbb !important;
}
.react-calendar-timeline{

.rct-hl-odd {
  background: white;
}
.texts {
  weight:60px;
  height:45px;
  border:1px solid red;
  /* background: linear-gradient(337deg, #f0f0f0 0, #f0f0f0 50.5%, #f0f0f0 50.5%, #aaa 50.5%, #f0f0f0 54%, #f0f0f0 50.5%) ; */
}

}
  
`
