import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    ConfigProvider,
    Form,
    Input,
    InputNumber,
    Row,
    Space
} from 'antd';
import { useState } from 'react';
const { TextArea } = Input;

const OrderForm = ({ layout, verifySupplierNumberAndOrderFlag }) => {
    const [OrderFormDataSource, setOrderFormDataSource] = useState([{}]);
    return (
        <ConfigProvider componentSize="small">
            <Form.List name="purchase_orders" initialValue={OrderFormDataSource}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <div key={field.key}>
                                <Space style={{ display: '', marginBottom: 8 }} align="">
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'code']}
                                        fieldKey={[field.fieldKey, 'code']}
                                        label="订单号"
                                        rules={[
                                            {
                                                required: verifySupplierNumberAndOrderFlag,
                                                message: '请输入订单号',
                                            },
                                            {
                                                pattern: /^[^\s]+$/,
                                                message: '订单号前后不能包含空格',
                                            },
                                        ]}
                                        size="small"
                                    >
                                        <Input placeholder="" />
                                    </Form.Item>
                                    {index === fields.length - 1 && (
                                        <Button type="dashed" icon={<PlusOutlined />} onClick={() => add()} style={{ marginTop: '5px', fontSize: '12px' }}>
                                            订单
                                        </Button>
                                    )}
                                    {fields.length > 1 && (
                                        <Button type="primary" icon={<MinusOutlined />} danger onClick={() => remove(field.name)} style={{ marginTop: '5px', fontSize: '12px' }}>
                                            删除
                                        </Button>
                                    )}
                                </Space>
                                <Form.List
                                    name={[field.name, 'po_items']}
                                    initialValue={OrderFormDataSource[index]?.po_items || [{}]}
                                    rules={[
                                        {
                                            validator: async (_, po_items) => {
                                                if (!po_items || po_items.length < 1) {
                                                    return Promise.reject(new Error('请至少添加一个项次号'));
                                                }
                                            }
                                        }
                                    ]}
                                    size="small"
                                >
                                    {(fields2, { add: add2, remove: remove2 }) => (
                                        <>
                                            {fields2.map((field2, index2) => (
                                                <div key={field2.key}>
                                                    <Row>
                                                        <Col {...layout}>
                                                            <Form.Item
                                                                {...field2}
                                                                name={[field2.name, 'seq']}
                                                                fieldKey={[field2.fieldKey, 'seq']}
                                                                label="项次号"
                                                                rules={[
                                                                    {
                                                                        required: verifySupplierNumberAndOrderFlag,
                                                                        message: '请输入项次号',
                                                                    }
                                                                ]}
                                                                size="small"
                                                            >
                                                                <InputNumber
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                    min={0}
                                                                    max={999999999999999}
                                                                    step={1}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col {...layout}>
                                                            <Form.Item
                                                                {...field2}
                                                                name={[field2.name, 'quantity']}
                                                                fieldKey={[field2.fieldKey, 'quantity']}
                                                                label="数量"
                                                                rules={[
                                                                    {
                                                                        required: verifySupplierNumberAndOrderFlag,
                                                                        message: '请输入数量',
                                                                    },
                                                                ]}
                                                                size="small"
                                                            >
                                                                <InputNumber
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                    min={0}
                                                                    max={999999999999999}
                                                                    step={1}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col  {...layout}>
                                                            <Form.Item
                                                                {...field2}
                                                                name={[field2.name, 'notes']}
                                                                fieldKey={[field2.fieldKey, 'notes']}
                                                                label="备注"
                                                                labelCol={{ style: { width: 55 } }}
                                                                size="small"
                                                            >
                                                                <TextArea
                                                                    autoSize={{ minRows: 1, maxRows: 8 }}
                                                                    maxLength={20}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => add2()}
                                                                style={{ width: '100%', marginLeft: '10px', marginTop: '5px' }}
                                                                icon={<PlusOutlined />}
                                                            >
                                                                项次号
                                                            </Button>
                                                        </Col>
                                                        <Col>
                                                            {fields2.length > 1 && (
                                                                <Button
                                                                    type="primary"
                                                                    danger
                                                                    onClick={() => remove2(field2.name)}
                                                                    style={{ marginLeft: '12px', marginTop: '5px' }}
                                                                    icon={<MinusOutlined />}>
                                                                    项次号
                                                                </Button>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </Form.List>
                            </div>
                        ))}
                    </>
                )}
            </Form.List>
        </ConfigProvider>
    )
}

export default OrderForm