import axios from 'axios'

const BASE_URL = process.env.REACT_APP_HOST_DOMAIN || "https://k8s.bielcrystal.com/test-fastpass-visitor-service"

const request = axios.create({
  baseURL:BASE_URL
})


// 请求拦截
request.interceptors.request.use((config) => {
  return config
}, (err) => {
  return Promise.reject(err)
})

// 响应拦截
request.interceptors.response.use((res) => {
  return res
}, err => {
  return Promise.reject(err.response)
})

export default request
