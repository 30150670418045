import FormInformation from "page/components/formInformation"
import Styles from "./style.js"


const CreateAppointment = () => {
  const isReserve = true

  return (
    <Styles>
        <FormInformation isReserve={isReserve} />
    </Styles>
  )
}
export default CreateAppointment
