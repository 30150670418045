import 'antd/dist/antd.css';
import GlobalMetadataContextProvider from 'page/contexts/GlobalMetadataContext';
import Layouts from './page/layout';

function App() {

  return (
    <GlobalMetadataContextProvider>
      <Layouts />
    </GlobalMetadataContextProvider>
  );
}

export default App;