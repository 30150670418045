export const columns = [
    {
        title: '项次',
        dataIndex: 'seq',
        key: 'seq',
        width: 55,
        align: 'center'
    },
    {
        title: '货品号',
        dataIndex: 'material_code',
        key: 'material_code',
    },

    {
        title: '品名',
        dataIndex: 'material_name',
        key: 'material_name',
    },
    {
        title: '规格',
        dataIndex: 'material_desc',
        key: 'material_desc',
    },
    {
        title: '单位',
        dataIndex: 'unit',
        key: 'unity',
        align: 'center',
    },
    {
        title: '数量',
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'center',
        width: 70,
    },
    {
        title: '备注',
        dataIndex: 'notes',
        key: 'notes',
    }
];