import * as Sentry from '@sentry/react';
import SentryRRWeb from "@sentry/rrweb";
import { BrowserTracing } from '@sentry/tracing';
import AppointmentDetails from 'page/appointmentDetails';
import CreateAppointment from 'page/createAppointment';
import ModifyReservation from 'page/modifyReservation';
import PrivacyPolicy from 'page/privacyPolicy';
import SearchAppointment from 'page/searchAppointment';
import TimeLine from 'page/timeLine';
import React from 'react';
import {
  Route,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";


const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || "test"
const SENTRY_R_R_WEB_SAMPLE_RATE = process.env.REACT_APP_SENTRY_R_R_WEB_SAMPLE_RATE || 0
export const OPEN_SENTRY_R_R_WEB_TRACE = Math.random() <= SENTRY_R_R_WEB_SAMPLE_RATE


let sentry_integrations = [
  new BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
      React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    ),
    tracingOrigins: ["localhost", "k8s.bielcrystal.com", "bielcrystal.com"],
  })
]

if (OPEN_SENTRY_R_R_WEB_TRACE) {
  sentry_integrations.push(
    new SentryRRWeb()
  )
}

Sentry.init({
  dsn: "https://51585e65164647418e219ab5ae61becc@o499525.ingest.sentry.io/6667932",
  defaultIntegrations: false,
  integrations: sentry_integrations,
  idleTimeout: 100000,
  tracesSampleRate: 1.0,
  environment: SENTRY_ENV,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const BasicRoute = () => (
  <SentryRoutes>
    <Route exact path="/" element={<CreateAppointment />} />
    <Route exact path="/search" element={<SearchAppointment />} />
    <Route path='/appointment/details/:id' element={<AppointmentDetails />} />
    <Route path='/privacy/policy' element={<PrivacyPolicy />} />
    <Route path='/edit/appointment' element={<ModifyReservation />} />
    <Route path='/timeLine/:area_id/:day' element={<TimeLine />} />
  </SentryRoutes>
);


export default BasicRoute;
