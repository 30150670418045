import Style from 'styled-components'

const AppointmentStyles = Style.div`
  .btn {
    text-align:right;
  }

  .booking-card {
    margin-top:15px;
  }

  .number {
      font-weight:700;
      color:red
  }
  .member {
      font-weight:700;
  }
  .program {
    font-weight:700;
  }
  .strategy {
    font-weight:700;
    color:red
  }
  @media screen and (max-width: 600px) {
    width:800px;
  }
  
`

export default AppointmentStyles