import moment from 'moment';
import { deWeight } from './util';


export const disabledMinutes = (selectedHour) => {
    let tempDay = JSON.parse(sessionStorage.getItem('bookingData'))
    const selectTime = moment(tempDay.day).format("YYYY-MM-DD")
    let startTimes = new Date(+new Date() + 8 * 3600 * 1000).toISOString().split("T")[1].split(".")[0];
    let timeArr = startTimes.split(":");
    let minutes = [];
    if (selectTime === moment(new Date()).format("YYYY-MM-DD")) {
        if (selectedHour === parseInt(timeArr[0])) {
            for (let i = 0; i < parseInt(timeArr[1]); i++) {
                minutes.push(i);
            }
        }
    }
    return minutes;
};

export const disabledEndMinutes = (selectedHour) => {
    let tempDay = JSON.parse(sessionStorage.getItem('bookingData'))
    const selectTime = moment(tempDay.day).format("YYYY-MM-DD")
    let startTimes = new Date(+new Date() + 8 * 3600 * 1000).toISOString().split("T")[1].split(".")[0];
    let timeArr = startTimes.split(":");
    let minutes = [];


    if (tempDay) {
        if (selectTime !== moment(new Date()).format("YYYY-MM-DD")) {
            if (selectedHour === 9) {
                for (let i = 0; i < 30; i++) {
                    minutes.push(i);
                }
            }
        } else {
            if (selectedHour === parseInt(timeArr[0])) {
                for (let i = 0; i < parseInt(timeArr[1]); i++) {
                    minutes.push(i);
                }
            }
            if (selectedHour === 8) {
                for (let i = 0; i < 30; i++) {
                    minutes.push(i);
                }
            }
        }
    }
    if (selectedHour === 12) {
        for (let i = 30; i < 60; i++) {
            minutes.push(i);
        }
    }
    if (selectedHour === 18) {
        for (let i = 30; i < 60; i++) {
            minutes.push(i);
        }
    }
    if (selectedHour === 21) {
        for (let i = 30; i < 60; i++) {
            minutes.push(i);
        }
    }
    return minutes;
};

// 限制开始时间
const limitHours = (hours) => {
    let tempHours = [...hours]
    let tempDay = JSON.parse(sessionStorage.getItem('bookingData'))
    if (tempDay) {
        const selectTime = moment(tempDay.day).format("YYYY-MM-DD")
        let time = new Date(+new Date() + 8 * 3600 * 1000).toISOString().split("T")[1].split(".")[0];
        // 当时今天的时候,从此刻往后面的时间选择
        if (selectTime === moment(new Date()).format("YYYY-MM-DD")) {
            let timeArr = time.split(":");
            const limitHour = parseInt(timeArr[1]) >= 30 ? parseInt(timeArr[0]) + 1 : parseInt(timeArr[0])
            for (var i = 0; i < limitHour; i++) {
                hours.push(i)
            }
        }

    }
    hours = deWeight(hours)
    return hours;
}

export const disabledHours = () => {
    let hours = [0, 1, 2, 3, 4, 5, 6, 7, 12, 21, 22, 23];

    return limitHours(hours)
};
export const disabledEndHours = () => {
    let hours = [0, 1, 2, 3, 4, 5, 6, 7, 22, 23];
    return limitHours(hours)

}