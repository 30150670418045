import Style from 'styled-components'

const MaterialStyle = Style.div`
width: 850px;
height:600px;
margin:0 auto;
border:1px solid #aaa;
  .table-notice {
    width: 20px !important;
    height: 16px;
    text-align:center !important;
    font-weight:600
  }
  .table-content {
    display: flex;
    width:1100px;
    margin-top:10px;
    min-height: calc(450px - 90px);
    transform: scale(0.75);
    transform-origin: left top; /* 设置缩放中心为左上角 */
  }
  .table-right {
    flex:20; 
  }
 
  .header{
    display: flex;
  }

  .header-left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex:1
  }
  .material-content {
    width:97%;
    margin:0 auto;
  }

  .header-left img {
    display: block;
    margin-right: 10px;
    width:85px;
    height:85px;
  }

  .title {
    font-size:24px;
    text-align: left;
  }

  .main-header {
    display: flex;
    flex-direction: column;
    margin-top:10px;
  }
  
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bottom p {
    display: inline-block;
    margin-right: 20px 
  }
  
  .main-header .bottom p {
    flex:1
  } 
  .qrcode {
    width:80px;
    height:80px;
  }
  .footer {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 10px;
    height:70px
  }
  
  .footer p:nth-child(1),
  .footer p:nth-child(2) {
    grid-column: span 1;
  }
  
  .footer p:nth-child(3),
  .footer p:nth-child(4) {
    grid-column: span 2;
  }
  

  .ant-table-thead > tr >th{
    background: white !important;
  }
  
  .print-left {
    flex:20
  }
 
  .hidden {
    display: none;
  }

  .table-right .ant-table-thead > tr > th {
    text-align: center;
  }

  .table-right .ant-table-tbody > tr > td {
    text-align: center;
  }

  .table-right .ant-table-cell {
    border: 1px solid black !important;
  }
  .ant-table-thead > tr > th {
    font-weight:600
  }
  .ant-table td, .ant-table th {
    white-space: normal; /* 允许内容换行 */
    word-wrap: break-word; /* 允许单词内换行 */
    word-break: break-all; /* 允许单词间换行 */
  }
  .table-right .ant-table {
    font-size: 18px !important; 
    font-weight:600
  }
`
export default MaterialStyle