import { Spin, message } from "antd";
import moment from 'moment';
import { StrategiesTimeline } from 'page/components/StrategiesTimeLine';
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { gettimeTableWay } from 'utils/util';
const TimeLine = () => {
    const [timeLineloading, setTimeLineLoading] = useState(false)
    const [timeTableData, setTimeTableData] = useState({ groups: [], items: [] })
    const { area_id, day } = useParams()

    const getTimeTable = async () => {
        if (area_id && day) {
            try {
                setTimeLineLoading(true)
                const getTimeTableData = await gettimeTableWay(area_id, day)
                setTimeTableData(getTimeTableData)
            } catch (error) {
                console.log(error, 'this is error');
            } finally {
                setTimeLineLoading(false)
            }
        } else {
            message.info("请输入正确的路径参数")
        }
    }

    useEffect(() => {
        getTimeTable()
    }, [area_id, day])


    return (
        <Spin spinning={timeLineloading}>
            {!timeLineloading && <StrategiesTimeline timeTableData={timeTableData} selectOneDay={moment(day).format("YYYY-MM-DD")} />}
        </Spin>
    )
}

export default TimeLine