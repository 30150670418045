import Style from 'styled-components'

const Styles = Style.div`
  @media screen and (max-width: 450px) {
    .strategiesTime {
      display:none
    }
  }
`

export default Styles